import Divider from '@material-ui/core/Divider';
import * as React from 'react';
import { ReactNode } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { contextPath, FOOD_ONE_TIME_ENABLED_COUNTRIES, GOOODS_ONE_TIME_ENABLED_COUNTRIES, indianTypes } from '../../constants';
import { GlobalDonationState } from './../../models/globalDonationState';
import NavigationComponent from './NavigationComponent';
export interface OwnProps {
    handleDrawerClose: () => void;
    t: (key: string, options?: any) => ReactNode;
}

export interface StateProps {
    selectedLang: any;
    loggedInUserDetail: any;
    sidebarDetail: any;

}

class DynamicSideBar extends React.Component<StateProps & OwnProps> {

    public render() {
        const navComponent: React.ReactElement[] = [];
        const organizationTypes = ['org_type_ngo']
        /* navComponent.push(<><NavigationComponent key={`home`}
            // @ts-ignore
            routeLink={`/`}
            activeLink=''
            name={`Dashboard`}
            handleClose={this.props.handleDrawerClose} /><Divider /></>) */

        /* navComponent.push(<NavigationComponent key={'form'}
            // @ts-ignore
            routeLink={`/login`}
            activeLink='FormExample'
            name={`Form`}
            handleClose={this.props.handleDrawerClose} />) */
        navComponent.push(<>
            <NavigationComponent key={'dashboard'}
                // @ts-ignore
                routeLink={`${contextPath}/dashboard`}
                activeLink={''}
                name={this.props.t('navigations.dashboard.title.dashboard')}
                handleClose={this.props.handleDrawerClose} /></>);
        navComponent.push(<Divider />);
        navComponent.push(<NavigationComponent key={'organization-profile'}
            // @ts-ignore
            routeLink={`${contextPath}/dashboard/organization-profile?type=organization-profile`}
            activeLink='organization-profile'
            name={this.props.t('navigations.organization.title.profile')}
            handleClose={this.props.handleDrawerClose} />)
        navComponent.push(<Divider />);
        navComponent.push(<NavigationComponent key={'organization-users'}
            // @ts-ignore
            routeLink={`${contextPath}/dashboard/organization-users?type=organization-users`}
            activeLink='organization-users'
            name={this.props.t('navigations.organization.title.userManagement')}
            handleClose={this.props.handleDrawerClose} />)
        navComponent.push(<Divider />);

        /* navComponent.push(<NavigationComponent key={'monetary'}
            // @ts-ignore
            routeLink={`${contextPath}/dashboard/monetary?type=monetary-donation`}
        activeLink='monetary-donation'
        name={`Monetary`}
            handleClose={this.props.handleDrawerClose} />) */
        if (this.props.loggedInUserDetail.countryCode === 'CA') {
            navComponent.push(<NavigationComponent key={'community-grant'}
                // @ts-ignore
                routeLink={`${contextPath}/dashboard/community-grant?type=community-grants`}
                activeLink='community-grants'
                name={this.props.t('navigations.donationTypes.title.communityGrants')}
                handleClose={this.props.handleDrawerClose} />)
        }

        if (this.props.loggedInUserDetail.organizationType &&
            this.props.loggedInUserDetail.organizationType.keyText === 'org_type_ngo') {
            navComponent.push(<NavigationComponent key={'donation-money'}
                // @ts-ignore
                routeLink={`${contextPath}/dashboard/donation-money?type=donation-money`}
                activeLink='donation-money'
                name={this.props.t('navigations.donationTypes.title.monetaryDonation')}
                handleClose={this.props.handleDrawerClose} />)
        }

        if (this.props.loggedInUserDetail.organizationType &&
            this.props.loggedInUserDetail.organizationType.keyText === 'org_type_ngo' &&
            indianTypes.indexOf(this.props.loggedInUserDetail.countryCode) == -1) {
            navComponent.push(<NavigationComponent key={'donation-goods'}
                // @ts-ignore
                routeLink={`${contextPath}/dashboard/donation-goods?type=donation-goods`}
                activeLink='donation-goods'
                name={this.props.t('navigations.donationTypes.title.goodsRecurringDonation')}
                handleClose={this.props.handleDrawerClose} />)
        }
        if (this.props.loggedInUserDetail.countryCode === 'GB') {
            navComponent.push(<><NavigationComponent key={`unmanned-collection`}
                // @ts-ignore
                routeLink={`${contextPath}/dashboard/unmanned-collection?type=unmanned-collection`}
                activeLink='unmanned-collection'
                name={this.props.t('navigations.donationTypes.title.unmannedCollection')}
                handleClose={this.props.handleDrawerClose} /></>);
        }
        if (this.props.loggedInUserDetail.organizationType &&
            organizationTypes.includes(this.props.loggedInUserDetail.organizationType.keyText) &&
            GOOODS_ONE_TIME_ENABLED_COUNTRIES.includes(this.props.loggedInUserDetail.countryCode)) {
            navComponent.push(<NavigationComponent key={'one-time-goods'}
                // @ts-ignore
                routeLink={`${contextPath}/dashboard/one-time-goods?type=one-time-goods`}
                activeLink='one-time-goods'
                name={this.props.t('navigations.donationTypes.title.oneTimeGoodsDonation')}
                handleClose={this.props.handleDrawerClose} />)
        }
        if (this.props.loggedInUserDetail.organizationType &&
            organizationTypes.includes(this.props.loggedInUserDetail.organizationType.keyText) &&
            FOOD_ONE_TIME_ENABLED_COUNTRIES.includes(this.props.loggedInUserDetail.countryCode)) {
            navComponent.push(<NavigationComponent key={'one-time-food'}
                // @ts-ignore
                routeLink={`${contextPath}/dashboard/one-time-food?type=one-time-food`}
                activeLink='one-time-food'
                name={this.props.t('navigations.donationTypes.title.oneTimeFoodDonation')}
                handleClose={this.props.handleDrawerClose} />)
        }
        if (this.props.loggedInUserDetail.countryCode === 'MX') {
            navComponent.push(<Divider />);
            navComponent.push(<><NavigationComponent key={'social-impact'}
                // @ts-ignore
                routeLink={`${contextPath}/dashboard/social-impact?type=social-impact`}
                activeLink='social-impact'
                name={this.props.t('navigations.socialImpactReport.title.socialImpact')}
                handleClose={this.props.handleDrawerClose} /></>);


            navComponent.push(<><NavigationComponent key={'social-impact-monetary'}
                // @ts-ignore
                routeLink={`${contextPath}/dashboard/social-impact-monetary?type=social-impact-monetary`}
                activeLink='social-impact-monetary'
                name={this.props.t('navigations.socialImpactReport.title.socialImpactMonetary')}
                handleClose={this.props.handleDrawerClose} /></>)
        }

           if(this.props.sidebarDetail?.data){
            Object.keys(this.props.sidebarDetail?.data).forEach((value:any)=>{
                const sidebarRoute = this.props.sidebarDetail?.data[value];
                navComponent.push(<Divider />);
                navComponent.push(<><NavigationComponent key={sidebarRoute.key+'-menu-title'}
                    // @ts-ignore
                    routeLink={`${contextPath}/${sidebarRoute.routeLink}`}
                    activeLink={sidebarRoute.key} 
                    name={sidebarRoute.translation[this.props.selectedLang]}
                    handleClose={this.props.handleDrawerClose}
                    data-testid={sidebarRoute.key+"-route-click"} /></>)
                 })
            }

            
        return <div className='nav-items-txt'>
            {navComponent}
        </div>;
    }
}

const mapStateToProps = (state: GlobalDonationState): StateProps => ({
    selectedLang: state.selectedLang,
    loggedInUserDetail: state.loggedInUserState.userDetail,
    sidebarDetail: state.sidebarState.sidebarData
    });

export default withTranslation()(connect(mapStateToProps, null)(DynamicSideBar));