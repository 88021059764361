import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'src/polyfils/customRouter';
import withRouter from 'src/polyfils/customRouter';
import { GlobalDonationState } from '../../models/globalDonationState';
import { withStyles, Typography } from '@material-ui/core';
import { registerUser, getSelectArea } from '../../state/userLogin/registerUserActions';
import styles from '../menu/ThemeStyle';
import '../../styles/registerUser.css'
import LoaderComponent from '../LoaderComponent';
import ErrorSnackbar from '../appMessage/ErrorSnackbar';
import { contextPath, countryCodeToMarketCode } from '../../constants';
import HeaderComponent from '../HeaderComponent';
import FooterComponent from '../FooterComponent';
import OrgDetailsFormComponent from './OrgDetailsFormComponent';
import OrgPrimaryUserDetailsComponent from './OrgPrimaryUserDetailsComponent';
import { coutryCodeToCountryName } from '../../constants'
import { Trans, withTranslation } from 'react-i18next';
import { i18n as i18nI } from 'i18next';
import analytics from '../../adobe-analytics/analytics';
import { NavLink } from 'react-router-dom';
import { ANALYTICS } from '../../adobe-analytics/analytics-dataelemets';

export interface OwnProps {
    formAlias: string;
    submissionUrl?: string;
    classes: any;
    appi18n: any;
    handleSubmit: (data: any) => void;
    t: (key: string, options?: any) => ReactNode;
    i18n: i18nI
}

export interface StateProps {
    submissionData?: any;
    selectedLang: any;
    registerUserData: any;
    orgType?: any;
    formAlias?: any;
}

export interface DispatchProps {
    register: (data: any) => void;
    getSelectAreaData: (data: any) => void;
}

const appi18n: any = {
    congrats: {
        en: 'Congratulations, you have registered successfully!',
        es: '¡Felicitaciones, se ha registrado correctamente!',
        fr: 'Félicitations, vous vous êtes inscrit avec succès!',
        ja: 'おめでとうございます。登録に成功しました。',
        zh: '恭喜，您已成功注册！'
    },
    loginPage: {
        en: 'Redirecting you to login page',
        es: '¡Felicitaciones, se ha registrado correctamente!',
        fr: 'Vous rediriger vers la page de connexion',
        ja: 'ログインページにリダイレクトする',
        zh: '重定向到登录页面'
    },
};

class RegistrationComponent extends React.PureComponent<OwnProps & RouteComponentProps & StateProps & DispatchProps> {
    public state = {
        orgType: '',
        formAlias: '',
        showConfirmDialog: false,
        formData: {},
        showCancelPopup: false,
        preferdLanguage: 'en',
        displyUserDdetailForm:false,
        orgDetails:{
            orgLegalName:'',
            taxId:'',
            city:'',
            province:'',
            organizationType: '',
            governmentCategory:'',
            addressLine1:'',
            postalCode:'',
            telephone: ''
        },
        primaryUserDdetails:{},
        returned:false
    };

    public options: any;

    public componentWillMount() {
        this.options = {
            ...this.options,
            noAlerts: true,
            language: localStorage.getItem('lang')
        }
        this.setState({
            preferdLanguage: localStorage.getItem('lang') || 'en'
        })
        window.lang = localStorage.getItem('lang') || 'en';
        window.countryName = localStorage.getItem('countryName');
        window.countryCode = localStorage.getItem('countryCode');
        if (!localStorage.getItem('lang') && !localStorage.getItem('countryName')) {
            this.props.history.push(`${contextPath}/Public/landingPage`);
        }
    }

    private updateLanguage = () => {
      if(localStorage.getItem('lang') && this.state.preferdLanguage !== localStorage.getItem('lang')) {
        this.setState({
          preferdLanguage: localStorage.getItem('lang')
        })
      } else if(!localStorage.getItem('lang') && this.state.preferdLanguage !== 'en') {
        this.setState({
          preferdLanguage: 'en'
        })
      }
    }

    public componentDidMount() {
        this.props.getSelectAreaData({});
        window.lang = localStorage.getItem('lang') || 'en';
        window.countryName = localStorage.getItem('countryName');
        window.countryCode = localStorage.getItem('countryCode');

        this.updateLanguage();

        if (!localStorage.getItem('lang') && !localStorage.getItem('countryName')) {
            this.props.history.push(`${contextPath}/Public/landingPage`);
        }
        analytics.trackPageInfoGdmsExternal(ANALYTICS.registerPage.registerOrgDetailsPage1);
    }

    public componentDidUpdate() {
        window.lang = localStorage.getItem('lang') || 'en';
        window.countryName = localStorage.getItem('countryName');
        window.countryCode = localStorage.getItem('countryCode');
        this.updateLanguage();

        if (!localStorage.getItem('lang') && !localStorage.getItem('countryName')) {
          this.props.history.push(`${contextPath}/Public/landingPage`);
        }
    }

    public componentWillReceiveProps(newProps: any) {
        if (newProps.selectedLang !== this.state.preferdLanguage) {
            this.options = {
                ...this.options,
                language: newProps.selectedLang
            }
        }
        this.setState({
            preferdLanguage: localStorage.getItem('lang') || 'en'
        })
        if (this.props.i18n.language !== localStorage.getItem('country') && localStorage.getItem('country') &&
            localStorage.getItem('countryCode') === 'MX'){
            const lang = localStorage.getItem('countryCode') === 'MX' ?
                localStorage.getItem('country') : localStorage.getItem('lang');
                this.props.i18n.changeLanguage(lang || 'en')
        }
        if (this.props.i18n.language !== localStorage.getItem('lang') && localStorage.getItem('lang') &&
            localStorage.getItem('countryCode') !== 'MX'){
            this.props.i18n.changeLanguage(localStorage.getItem('lang') || 'en')
        }
    }
    private goToNextStep = (data:any) => {
        this.setState({
            orgDetails: { ...data},
            returned:false
        })
        this.setState({ displyUserDdetailForm:true})
    }

    private backToOrgForm = (data:any) => {
        this.setState({ displyUserDdetailForm: false })
        this.setState({
            primaryUserDdetails: { ...data},
            returned:true
        })
    }

    private handleSubmit = (formData: any) => {

        this.setState({ showConfirmDialog: false });
        let countryCode: string = localStorage.getItem('countryCode') || "CA";
        const payload = {
            // @ts-ignore
            orgLegalName: this.state.orgDetails?.orgLegalName,
            organizationType: this.state.orgDetails?.organizationType,
            governmentCategory: this.state.orgDetails?.governmentCategory ? this.state.orgDetails?.governmentCategory : null,
             // @ts-ignore
            taxId: this.state.orgDetails?.taxId,
            country: localStorage.getItem('countryName'),
            countryCode: countryCode,
            market: countryCodeToMarketCode[countryCode],
            externalSource: true,
            isRegister: true,
            address:{
                // @ts-ignore
                province: this.state.orgDetails?.province?.label,
                // @ts-ignore
                city: this.state.orgDetails?.city,
                // @ts-ignore
                addressLine1: this.state.orgDetails?.addressLine1,
                // @ts-ignore
                postalCode: this.state.orgDetails?.postalCode,
                country: localStorage.getItem('countryName'),
            },
            telephone: this.state.orgDetails?.telephone,
            ...formData,
            primaryOrgUser:{
                ...formData.primaryOrgUser,
                prefLanguage: this.state.preferdLanguage,
            }
        }
        localStorage.setItem('userEmail', formData.primaryOrgUser.userEmail)
        this.props.register({ ...payload, orgAdditionalDetails: { isRegisteredNew: true }})
    };

    public render() {
        // @ts-ignore
        const { classes, registerUserData } = this.props;
        const selectedCountry = localStorage.getItem('country') || '';
        const country = coutryCodeToCountryName[selectedCountry || 'en_IN'];
        const formHeader = (
            <div className={classes.headerSection}>
                <Typography className={classes.header}>
                    {this.props.t('orgRegForm.header')}
                </Typography>
                <Typography className={classes.subHeader}>
                    {<Trans i18nKey='orgRegForm.subHeader.marketLocation'>
                        Market Location/Language: {{ country }}. Go to <NavLink to={`${contextPath}/Public/landingPage`}
                            className='link-subheader'>Landing page</NavLink>  to change it.
                    </Trans>}
                </Typography>
            </div>
        )
        return (<>
            <HeaderComponent classes={classes} />
            <div className='reg-main-container'>
                <div className={`register-user-container ${classes.formCenter}`}>
                    {
                        !registerUserData.registerUserSuccess ?
                            <div className={classes.formContainer}>
                                {formHeader}
                                {!this.state.displyUserDdetailForm &&
                                    <OrgDetailsFormComponent
                                    preferdLanguage={this.state.preferdLanguage}
                                    appi18n={appi18n}
                                    classes={this.props.classes}
                                    countryCode={localStorage.getItem('countryCode')}
                                    submit={this.goToNextStep}
                                    defaultFormValues={this.state.orgDetails}
                                    returned={this.state.returned}
                                    selectedLang={this.props.selectedLang}
                                />}
                                {this.state.displyUserDdetailForm &&
                                <OrgPrimaryUserDetailsComponent
                                    preferdLanguage={this.state.preferdLanguage}
                                    appi18n={appi18n}
                                    classes={this.props.classes}
                                    countryCode={localStorage.getItem('countryCode')}
                                    onSubmit={this.handleSubmit}
                                    goBack={this.backToOrgForm}
                                    defaultFormValues={this.state.primaryUserDdetails}
                                />}
                            </div>
                            :
                            <div className={classes.formContainer}>
                                <div className={classes.formElement}>
                                    <div className='register-success' />
                                    <Typography className={classes.header}>
                                        {this.props.t('orgRegForm.successMsg.header')}
                                    </Typography>
                                    <Typography className={classes.tempPwdTxt}>
                                        {this.props.t('orgRegForm.successMsg.tempPwdTxt')}
                                    </Typography>
                                    <Typography className={classes.subHeader}>
                                        {this.props.t('orgRegForm.successMsg.redirectTxt')}
                                    </Typography>
                                </div>
                            </div>}
                    {registerUserData.registerUserLoading && <LoaderComponent fullScreen={true} />}
                </div>
            </div>
            {registerUserData?.selectAreaLoading && <LoaderComponent fullScreen={true} />}
            <ErrorSnackbar errorType='registerUserFailure' />
            <FooterComponent />
        </>);
    }
}

const mapStateToProps = (state: GlobalDonationState): StateProps => ({
    submissionData: state.submissionDataState.submissionData,
    selectedLang: state.selectedLang,
    registerUserData: state.registerUser,
});

const mapDispatchToProps = (dispatch: React.Dispatch<any>): DispatchProps => ({
    register: (data: any) => dispatch(registerUser(data)),
    getSelectAreaData: (data: any) => dispatch(getSelectArea(data)),
});

export default withTranslation()(withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(RegistrationComponent))));
