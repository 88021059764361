import { createStyles, Grid, IconButton, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DeleteIcon from '@material-ui/icons/Delete';
import { getEncryptedString } from 'dynamic-form-components/lib/crypto';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { IFileDetails } from 'src/models/iOneTimeFoodDTO';
import { downloadLocalFile } from '../utils';

const config = require(`../../../config.${process.env.NODE_ENV}.json`);


const useStyles = makeStyles(() => createStyles({
    root: {
        textAlign: 'center',
        cursor: 'pointer',
        color: '#333',
        padding: '10px',
        marginTop: '20px',
        border: '2px solid #c1c1c1',
        borderStyle: 'dotted',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '68px'
    },
    icon: {
        marginTop: '16px',
        color: '#888888',
        fontSize: '42px',
    },
    label: {
        fontSize: '16px',
        fontFamily: 'Bogle',
        fontWeight: 500
    },
    filesType: {
        fontFamily: 'Bogle',
        fontSize: '14px',
        color: '#6d7278',
        marginTop: '8px'
    },
    tableContainer: {
        padding: '10px',
        backgroundColor: '#fbfbfb'

    },
    rowRoot: {
        '&:last-child td': {
            borderBottom: 0,
        },
        '&:last-child th': {
            borderBottom: 0,
        },
    }
}));


interface IFileUploaderProps
    extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    label: string,
    deleteFile: boolean,
    fileDownloader: (data: any) => void,
    onFileUpload: (file: File | null) => void,
    file?: File | IFileDetails | null,
    readOnly?: boolean,
    title?: string
}

export const SingleFileUpload: FC<IFileUploaderProps> = (props: IFileUploaderProps) => {
    const styles = useStyles();
    const { t } = useTranslation();

    const [file, setFile] = useState<any>(props.file);
    const onDrop = useCallback(
        (droppedFiles: any[]) => {
            setFile(droppedFiles[0])
            props.onFileUpload(droppedFiles[0])
        },
        []
    )
    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: props.accept,
        multiple: false
    })

    useEffect(() => {
      if(props.deleteFile) {
        handleRemove();
      }
    }, [props.deleteFile])

    const handleRemove = () => {
        setFile(null);
        props.onFileUpload(null)
    }

    const downloadFile = () => {
        if (file?.fileName) {
            const fileInfo = {
                fileId: file?.fileName,
                fileName: file?.fileName,
                directory: file?.uploadDir,
                container: file?.container
            }
            const encryptedFileInfo = encodeURIComponent(getEncryptedString(fileInfo));
            const dataUri = `files?fileInfo=${encryptedFileInfo}`;
            props.fileDownloader({
                name: `${file?.fileName}`,
                type: 'downloadFiles',
                actionUrl: `${config.gdmsServiceUrl}/${dataUri}`
            });
        }
        else if (file?.name) {
            downloadLocalFile(file);
        }
    }

    const fileName = file ? file?.name ? file.name : (file as any).fileName : '';
    return (
        <div data-testid = 'singleFileUpload'>
            {!file && <Grid container={true} item={true} xs={12} md={5}>
                <Grid container={true} item={true} xs={12}>
                    <Paper
                        variant='outlined'
                        data-testid = 'uploadFile'
                        className={styles.root}{...getRootProps()}>
                        <input

                            id={'fileupload'}
                            {...getInputProps()}
                        />
                        <Typography classes={{ root: styles.label }} >{props.label}</Typography>

                    </Paper>

                </Grid>
                <Typography classes={{ root: styles.filesType }}>{t('common.fileUpload.fileType')}</Typography>

            </Grid >}
            <Grid container={true} item={true} xs={12} md={8} lg = {5} style={{ marginBottom: '10px' }}>
                {file && (
                    <TableContainer data-testid='fileTable' component={Paper} elevation={3}
                      variant='elevation' className={styles.tableContainer}>
                        <Table aria-label='attached files table' size='small'>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold' }} >
                                      {props.title ?? t('common.fileUpload.columnFileName')}
                                      </TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow key={'row_0'} classes={{root: styles.rowRoot}}>
                                    <TableCell component='th' scope='row'>
                                        {fileName}
                                    </TableCell>
                                    <TableCell align='right' >
                                        <IconButton color='default'
                                            data-testid = 'downloadFile'
                                            aria-label='download File' onClick={() => downloadFile()} component='span'>
                                            <CloudDownloadIcon />
                                        </IconButton>
                                        <IconButton color='default'
                                            data-testid = 'deleteFile'
                                            disabled = {props.readOnly}
                                            aria-label='Delete File' onClick={() => handleRemove()} component='span'>
                                            <DeleteIcon />
                                        </IconButton></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                )}
            </Grid>
            {/* <ErrorMessageComponent errors={errors} fieldName={name} /> */}
        </div>
    )
}

export default SingleFileUpload;
