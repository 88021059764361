import { CircularProgress, makeStyles, Paper, TableRow, Typography, withStyles } from '@material-ui/core';
import ButtonElem from '@material-ui/core/Button';
import React, { FC, useRef, useState, ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next';
import MaterialTable, {IMTColoumn} from '../../../common/MaterialTable'

import { CloudDownload, CloudUpload, Delete } from '@material-ui/icons';

import GlobalDonationDataService from 'src/service';
import { getEncryptedString } from 'dynamic-form-components/lib/crypto';
import MUISnackBar  from '../../../common/AlertBox/MUISnackBar'
import { IFileDetails } from 'src/components/donations/moreDetailsSections/attachments/attachmentsTable';
import { CustomCell } from 'src/components/donations/moreDetailsSections/common/CustomCell';
import GenericConfirmDialog from 'src/components/dialogComponents/GenericConfirmDialog';


const config = require(`../../../../config.${process.env.NODE_ENV}.json`);

interface OwnProps {
  files: Map<string, IFileDetails>,
  reportId: string,
  finalized: boolean,
  setFileErrorState: any
}
const useStyles = makeStyles({
  title: {
      fontWeight: 'bold'
  },
  paper: { padding: '15px', backgroundColor: '#fbfbfb' },
  cell: {
      padding: '16px ',
      verticalAlign: 'middle',
      minWidth: '200px'
  },
  smallCelll: {
      width: '180px',
  },
  yesButton: {
    '&:hover': {
        color: '#041E42'
    }
},
noButton: {
    '&:hover': {
        color: '#041E42'
    }
}

})

const columnHeader: IMTColoumn[] = [
  { key: 'socialImpactReport.description' },
  { key: 'socialImpactReport.fileName' },
  { key: 'actions' },
]
const validateFileSize = (file: File): boolean=> {
  return file.size <= 10**7;
}
let errorMessage = '';
const supportedFileExtensions = ['.xls','.xlsx','.pdf','.jpeg','.png'];

const Button = withStyles({
root: {
  textTransform: 'capitalize'
}
})(ButtonElem);
const maxFileSize = '10 mb';
const supportedFileTypes = ['pdf', 'jpeg', 'png', 'xlx'];


const Attachments: FC<OwnProps> = ({files, reportId, finalized, setFileErrorState}) => {
  const { t } = useTranslation();
  const getTranslation = (key: string) => t(`monetaryDonation.attachmentsSection.${key}`);
  const classes = useStyles();

  const fileUploadEl = useRef<HTMLInputElement>(null);
  const formEl = useRef<HTMLFormElement>(null);
  const [showError, setErrorState] = useState(false);
  const [pendingAction, setPendingAction] = useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const [selectedFileId, setSelectedFileId] = useState('');
  const [fileMap, setFileMap] = useState(new Map(files));

  const handlErrorClose = () => {
      setErrorState(false);
  }

  const deleteFile = (code: string) => {
    setSelectedFileId(code);
    setDialogOpen(true);
  }

  const closeDialog = () => {
    setDialogOpen(false);
  }
  const uploadFile = (code: string) => {
      setSelectedFileId(code);
      if(fileUploadEl.current) {
        fileUploadEl.current.click();
      }
  }
  const onDeleteFile = async () => {
    setDialogOpen(false);
    setPendingAction(true);
    const isDeleted = await GlobalDonationDataService.deleteMonetoryReportAttachment(reportId, selectedFileId!);
    setPendingAction(false);
    if(isDeleted) {
      files.delete(selectedFileId);
      setFileErrorState(true);
      setFileMap(new Map(files));
    } else {
      const fileName = files.get(selectedFileId)?.fileName;
      errorMessage = t('monetaryDonation.attachmentsSection.failedToDelete').replace('{0}', fileName??'');
      setErrorState(true);
    }
  }

  const downloadFile = async (code: string) => {
      const fileData = files.get(code);
      setPendingAction(true);
      setSelectedFileId(code);
      if(fileData) {
        const file = fileData as IFileDetails;
        const fileInfo = {
          fileId: file?.fileName,
          fileName: file?.fileName,
          directory: file?.uploadDir,
          container: file?.container
      }
      const encryptedFileInfo = encodeURIComponent(getEncryptedString(fileInfo));
      const dataUri = `files?fileInfo=${encryptedFileInfo}`;
      try {
        await GlobalDonationDataService.getFileData({
          name: `${file?.fileName}`,
          type: 'downloadFiles',
          actionUrl: `${config.gdmsServiceUrl}/${dataUri}`
        });
        setPendingAction(false);
      } catch {
        setPendingAction(false);
        errorMessage =
                  t('monetaryDonation.attachmentsSection.failedToDownload').replace('{0}', fileData?.fileName ??'');
                  setErrorState(true);
      }

    }
  }

  const onFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
      const file: File = event.target.files?.item(0) as File;
      if(file) {
          if(validateFileSize(file)) {
              setErrorState(false);
              setPendingAction(true);
              const formData = new FormData();
              formData.set('file', file);
              const fileDetails = await GlobalDonationDataService.uploadMonetoryReportAttachment(reportId, selectedFileId, formData);
              setPendingAction(false);
              if(fileDetails) {
                files.set(selectedFileId, fileDetails);
                setFileMap(new Map(files));
              } else {
                errorMessage =
                  t('monetaryDonation.attachmentsSection.failedToUpload').replace('{0}', files.get(selectedFileId)?.fileName ??'');
                setErrorState(true);
              }
          } else {
              errorMessage = t('monetaryDonation.attachmentsSection.fileSizeError');
              setErrorState(true);
          }
          if(formEl.current) {
            formEl.current.reset();
          }
          const narrativeProjectReport = files.get('narrativeProjectReport');
          const financialReport = files.get('financialReport');
          setFileErrorState(!financialReport || !narrativeProjectReport)
      }
  };

  return <>
      <Paper classes = {{root: classes.paper}}>
          <MaterialTable
              getTranslation ={t}
              isHeadLight = {true}
              header = {columnHeader}>
                  {['narrativeProjectReport','financialReport'].map((key, index) => {
                    let fileName = '';
                    const file = fileMap.get(key);
                    if(file instanceof File) {
                      fileName = file?.name??'';
                    } else  {
                      fileName = (file as IFileDetails)?.fileName??'';
                    }

                    return (<TableRow key={index}>
                      <CustomCell
                          classes = {{root: classes.cell, sizeSmall: classes.smallCelll}}>
                          {index === 0 ? t('socialImpactReport.narrativeProjectReport'):t('socialImpactReport.financialReport')}*
                      </CustomCell>
                      <CustomCell
                            classes = {{root: classes.cell, sizeSmall: classes.smallCelll}}>
                          {fileName.length > 0?
                                  <Typography>{fileName}</Typography>
                                  : pendingAction && selectedFileId === key ? <CircularProgress size={30}/> :
                                  !finalized &&
                                  <Button data-testid={`upload_${index}`}
                                      color='default'
                                      variant = 'text'
                                      startIcon = {<CloudUpload color='primary' />}
                                      onClick={() => uploadFile(key)}>
                                      {t('common.buttonLabels.upload')}
                                  </Button>}
                      </CustomCell>
                      <CustomCell
                            classes = {{root: classes.cell, sizeSmall: classes.smallCelll}}>
                                {fileName.length > 0 &&
                              <>
                                 {pendingAction && selectedFileId === key ? <CircularProgress size={30}/> :
                                <>
                                  <Button data-testid={`download_${index}`}
                                        color='default'
                                        variant = 'text'
                                        startIcon = {<CloudDownload color='primary' />}
                                        onClick={() => downloadFile(key)}>
                                        {t('common.buttonLabels.download')}
                                </Button>
                                {!finalized &&
                                <Button data-testid={`delete_${index}`}
                                          color='default'
                                          variant = 'text'
                                          startIcon = {<Delete color='primary' />}
                                          onClick={() => deleteFile(key)}>
                                          {t('common.buttonLabels.delete')}
                                </Button>}
                                </>}
                            </>}
                      </CustomCell>
                      </TableRow>)
                  })}
          </MaterialTable>
          <MUISnackBar  open={showError} onClose={handlErrorClose} message = {errorMessage}/>
          <GenericConfirmDialog showDialog={dialogOpen}
              title={getTranslation('deleteFileDialogTitle')}
              message={getTranslation('deleteFileDialogMessage').replace('{0}',
              (fileMap.get(selectedFileId) as IFileDetails)?.fileName??'')}
              confirmButton={t('common.buttonLabels.yes')}
              cancelButton={t('common.buttonLabels.no')}
              cancel={closeDialog}
              confirm={onDeleteFile}
              cancelBtnClasses={classes.noButton}
              confirmBtnClasses={classes.yesButton}
          />
          <form ref={formEl}>
                  <input data-testid='fileUpload' type='file' accept={supportedFileExtensions.toString()}
                      width='0' height='0' ref={fileUploadEl} onChange={onFileChange}/>
              </form>
      </Paper>
      <Typography variant='subtitle2' color='textSecondary' >
        *{t('monetaryDonation.attachmentsSection.maxFileSize')}: {maxFileSize}
        | {t('monetaryDonation.attachmentsSection.fileType')}: {supportedFileTypes.toString()}
      </Typography>
  </>
};

export default Attachments;
