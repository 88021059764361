import React, { Component } from 'react';
import { connect } from 'react-redux';
import { GlobalDonationState } from '../../../models/globalDonationState';
import { viewOrDownloadFile } from 'src/state/ManageWorkflow/fileActions';
import { getEncryptedString } from 'dynamic-form-components/lib/crypto';
const config = require(`../../../config.${process.env.NODE_ENV}.json`);

export interface OwnProps {
    donation: any,
    donationType: string
}

export interface StateProps {
    key: string,
    component: any,
    donationFiles: [],
    orgFiles: [],
    files: []

}


export interface DispatchProps {
    downloadFile: (data: any) => void;
}


const style = {
    textDecoration: 'underline',
    color: 'blue',
    cursor: 'pointer'
};

const getKeyValue = (key: any) => {
    if (key != null && key.includes('money')) { return 'donationOfMoney'; }
    else if (key != null && key.includes('goods')) { return 'donationOfGoods'; }
    else { return '' };
}



class DonationAttachFilesView extends Component<OwnProps & DispatchProps & StateProps> {

    public state = {
        donation: {},
        key: '',
        donationFiles: [],
        orgFiles: [],
        files: []
    }

    constructor(props: any) {
        super(props);
        this.state = {
            donation: (this.props?.donation?.[getKeyValue(this.props?.donationType)]
                && this.props.donation?.[getKeyValue(this.props.donationType)]?.donation) ?
                this.props?.donation[getKeyValue(this.props.donationType)]?.donation : '',
            key: this.props.component.key,
            donationFiles: (this.props?.donation?.[getKeyValue(this.props.donationType)]
                && this.props?.donation?.[getKeyValue(this.props?.donationType)]?.donation) ?
                this.props?.donation?.[getKeyValue(this.props?.donationType)]?.donation?.donationDetail?.uploadFiles : [],
            orgFiles: (this.props.donation[getKeyValue(this.props.donationType)]
                && this.props?.donation?.[getKeyValue(this.props.donationType)]?.donation) ?
                this.props?.donation?.[getKeyValue(this.props.donationType)]?.donation?.donationDetail?.orgUploadFiles : [],
            files: (this.props.component.key === 'donationAttachments') ? this.state.donationFiles : this.state.orgFiles
        }
    }
    public componentWillMount() {
        this.setState({ files: (this.props.component.key === 'donationAttachments') ? this.state.donationFiles : this.state.orgFiles })
    }

    private downloadFile = (_event: any, filename: string, directoryname: string, containername: string) => {
        const fileInfo = {
            fileId: filename,
            fileName: filename,
            directory: directoryname,
            container: containername
        }
        const encryptedFileInfo = encodeURIComponent(getEncryptedString(fileInfo));
        const dataUri = `files?fileInfo=${encryptedFileInfo}`;
        this.props.downloadFile({
            name: `${filename}`,
            type: 'downloadFiles',
            actionUrl: `${config.gdmsServiceUrl}/${dataUri}`
        });
    }
    public render() {
        return (
            <div className='row'>
                <div className='col-md-8'>
                    <div className='form-group' />
                    {(this.state.files && this.state.files.length > 0) ? 'Attached Files' : ''}
                    {this.state.files ? this?.state?.files?.map(file => {
                        const name = 'fileName';
                        const uploadDir = 'uploadDir';
                        const container = 'container'
                        return <div key={file[name]} style={style}><div
                            onClick={() => this.downloadFile(this, file[name], file[uploadDir], file[container])}>{file[name]}</div></div>
                    }) : ''}
                </div>
            </div>
        );
    }
};

const mapStateToProps = (state: GlobalDonationState): OwnProps => ({
    donation: state.selectedDataState.addDataToStore,
    donationType: state.selectedMenuOption
});

const mapDispatchToProps = (dispatch: React.Dispatch<any>): DispatchProps => ({
    downloadFile: (data) => dispatch(viewOrDownloadFile(data))
});




export default (connect(mapStateToProps, mapDispatchToProps)(DonationAttachFilesView));