import  {FC } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormRadioButton } from '../../common'

export interface AppendixBOrgMemberQuestionnaireProps {
    heading?: string
    classes: any
    data?: any
    readOnly?: boolean
    disabled?: boolean
}

export const AppendixBOrgMemberQuestionnaire: FC<AppendixBOrgMemberQuestionnaireProps> = (props: any) => {

    const { t } = useTranslation()
    const { control, setValue, errors } = useFormContext()
    const requiredMsg = t('common.validationMsg.requiredField')

    const {
        govtOrPoliticalOffice,
        govtOrPoliticalOfficeDetails,
        govtOrPoliticalOfficeRelative,
        govtOrPoliticalOfficeRelativeDetails
    } = props?.data

    return (<div>
        <div className='row' style={{ marginTop: '50px' }}>
            <div className='col-md-8'>
                <FormRadioButton
                    id={'govtOrPoliticalOffice-radio-btn'}
                    className={'govtOrPoliticalOffice-radio-btn'}
                    classes={props?.classes}
                    formErrors={errors}
                    control={control}
                    radioButtonHeading={t('appendixBQuestionnaires.govtOrPoliticalOfficeQuestion')}
                    inputBoxHeading={t('appendixBQuestionnaires.govtOrPoliticalOfficeDetails')}
                    fieldName={'appendixBGovtOrPoliticalOffice'}
                    fieldNameInput={'appendixBGovtOrPoliticalOfficeDetails'}
                    defaultValue={govtOrPoliticalOffice}
                    required={true}
                    disabled={props?.disabled}
                    setValue={setValue}
                    noInputBox={false}
                    radioButtonValue={govtOrPoliticalOffice}
                    inputBoxValue={govtOrPoliticalOfficeDetails}
                    rules={{
                        required: requiredMsg
                    }}
                    />
            </div>
        </div>
        <div className='row'>
            <div className='col-md-8'>
                <FormRadioButton
                    id={'govtOrPoliticalOfficeRelative-radio-btn'}
                    className={'govtOrPoliticalOfficeRelative-radio-btn'}
                    classes={props?.classes}
                    formErrors={errors}
                    control={control}
                    radioButtonHeading={t('appendixBQuestionnaires.govtOrPoliticalOfficeRelativeQuestion')}
                    inputBoxHeading={t('appendixBQuestionnaires.govtOrPoliticalOfficeRelativeDetails')}
                    fieldName={'appendixBGovtOrPoliticalOfficeRelative'}
                    fieldNameInput={'appendixBGovtOrPoliticalOfficeRelativeDetails'}
                    defaultValue={govtOrPoliticalOfficeRelative}
                    required={true}
                    disabled={props?.disabled}
                    setValue={setValue}
                    noInputBox={false}
                    radioButtonValue={govtOrPoliticalOfficeRelative}
                    inputBoxValue={govtOrPoliticalOfficeRelativeDetails}
                    rules={{
                        required: requiredMsg
                    }}
                />
            </div>
        </div>
    </div>)
}
