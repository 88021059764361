//@ts-nocheck
import React, { useState, useContext, useEffect } from "react";
import Select from "../../commonComponents/uiComponents/LDSelect";
import Card from "../../commonComponents/uiComponents/LDCard";
import CardItem from "../../commonComponents/uiComponents/LDCardItem";
import Divider from "../../commonComponents/uiComponents/LDDivider";
// import TextArea from "../../commonComponents/uiComponents/LDTextArea";
import Button from "../../commonComponents/uiComponents/LDButton";
import LoaderComponent from "../../commonComponents/appComponents/loader/LoaderComponent";
import SpaceDonationService, { Service } from "src/spaceDonationService";
import { useQuery, useMutation } from "react-query";
import UserContext from "../../store/user-context";
import { useForm, Controller } from "react-hook-form";
import { toCapitalize } from "../../commonComponents/common/utils";
import analytics from "../../../../adobe-analytics/analytics";
import spacetracker from "../../../../adobe-analytics-space/space-tracker";
import { SPACEANALYTICS } from "../../../../adobe-analytics-space/analytics-space-dataelements";
import LDModal from "../../commonComponents/uiComponents/LDModal";
import {
  isNotEmpty,
  validateEmail,
  validatePhoneNumber,
} from "./../../commonComponents/common/utils";
import LDTextField from "../../commonComponents/uiComponents/LDTextField";
import { Plus } from "../../commonComponents/icons/icon";
import _ from "lodash";
import { Autocomplete, Checkbox, TextField, Tooltip , Snackbar , Alert } from "@mui/material";
import { InfoCircle } from "@livingdesign/icons";

export interface Props {
  nextPage?: any;
  prevPage?: any;
  setSelectedForm: any;
  setSelectedQuestion: any;
  selectedQuestion: any;
  focusAreas?: any;
  purposes?: any;
  customContact?: any;
  setCustomContact?: any;
  customContactSelected?: any;
  setCustomContactSelected?: any;
  secondaryContacts?: any;
  date?: any;
  refetch?: any;
}

const service: Service = SpaceDonationService;

const QuestionForm: React.SFC<Props> = (props) => {
  const { data, isFetching } = useQuery(
    ["fetchFocusAreas", { key: "focusArea", countryCode: "US" }],
    service.getSpaceDonationConfigurations,
    { refetchOnWindowFocus: false }
  );

  const { data: purposeData, isFetching: purposeIsFetching } = useQuery(
    ["fetchPurposes", { key: "spacePurposes", countryCode: "US" }],
    service.getSpaceDonationConfigurations,
    { refetchOnWindowFocus: false }
  );
  return (
    <>
      {isFetching || purposeIsFetching ? (
        <LoaderComponent />
      ) : (
        <QuestionFormSection
          focusAreas={data}
          {...props}
          purposes={purposeData}
          {...props}
        />
      )}
    </>
  );
};

export const QuestionFormSection: React.SFC<Props> = (props) => {
  useEffect(() => {
    analytics.trackPageInfoSpaceExternal(
      SPACEANALYTICS.onboardingSpaceExternalPage
    );
  }, []);
  const userContext: any = useContext(UserContext);
  const [purpose, setPurpose] = useState(props.selectedQuestion?.purpose);
  const [focusArea, setFocusArea] = useState(props.selectedQuestion?.focusArea);
  const [contact, setContact] = useState(props.selectedQuestion?.contact);
  const [description, setDescription] = useState(
    props.selectedQuestion?.description
  );
  const [showCustomUserPopUp, setShowCustomUserPopUp] = useState(false);
  let eventData=null;
  const {
    data: secondaryContacts,
    isFetching: secondaryContactsIsFetching,
    refetch,
  } = useQuery(
    ["getSecondaryContact", { orgId: userContext?.organization?.id }],
    service.getSecondaryContact,
    { refetchOnWindowFocus: false }
  );

  const { control, handleSubmit, errors, setValue, getValues } = useForm({
    mode: "onChange",
    defaultValues: {
      purpose: purpose ? purpose : "",
      focusArea: focusArea ? focusArea : "",
      contact: contact ? contact : "",
      description: description ? description : "",
    },
  });

  const onSubmit = () => {
    analytics.trackEventInfo({
      type: spacetracker.EVENTS.CLICK,
      actionOn: spacetracker.ACTION_ON.REQUEST_A_SPACE_DONATION_CONTINUE.name,
      location: SPACEANALYTICS.onboardingSpaceExternalPage.name,
    });
    props.setCustomContact(secondaryContacts);
    props.setSelectedQuestion(getValues());
    props.setSelectedForm(props.nextPage);
  };

  const clearContacts = () => {
    setValue("contact", "");
  };

  const handleOnChangeTextarea = (event: any) => {
    let pattern = /^[\w\-,.\s\b]+$/;
    let result = pattern.test(event.target.value);
    if (!result && !_.isEmpty(event.target.value)) {
      return;
    }
    setDescription(event.target.value);
    setValue("description", event.target.value);
  };

  const combinedOptions = userContext?.organization?.orgContactList?.concat(secondaryContacts ?? []) ?? [];

  return (
    <>
      {secondaryContactsIsFetching && <LoaderComponent />}
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="space-request-questions-form"
      >
        <Card className="space-request-questions-card">
          <CardItem className="space-request-questions-card-header-item">
            <div className="space-request-questions-card-header">
              Tell us about your event!
            </div>
          </CardItem>

          <CardItem className="space-request-questions-card-item">
            <div className="space-request-questions-card-question">
              1. What is the purpose of the event ?
            </div>
            <div className="space-request-questions-card-answer">
              <Controller
                control={control}
                name="purpose"
                defaultValue={"awareness"}
                required={true}
                data-testid="purpose"
                as={
                  <Select
                    value={purpose}
                    label={
                      <div className="space-request-questions-card-answer-text">
                        Purpose
                      </div>
                    }
                    onChange={(event: any) => {
                      setPurpose(event.target.value);
                      setValue("purpose", event.target.value);
                    }}
                  >
                    <option selected={true} value="">
                      {" "}
                      --Select An Option--{" "}
                    </option>
                    {props?.purposes?.purposesList?.map((fa: any) => {
                      return (
                        <option key={fa} value={fa}>
                          {fa}
                        </option>
                      );
                    })}
                  </Select>
                }
                rules={{
                  required: "Purpose is required",
                }}
              />
              {errors?.purpose?.message && (
                <span
                  className="reg-form-error-container"
                  style={{ color: "red" }}
                >
                  {errors?.purpose?.message}
                </span>
              )}
            </div>
            <Divider />
          </CardItem>

          <CardItem className="space-request-questions-card-item">
            <div className="space-request-questions-card-question">
              2. Which focus area best describes the event ?
            </div>
            <div className="space-request-questions-card-answer">
              <Controller
                control={control}
                name="focusArea"
                data-testid="focusArea"
                as={
                  <Select
                    value={focusArea}
                    label={
                      <div className="space-request-questions-card-answer-text">
                        Focus Area
                      </div>
                    }
                    onChange={(event: any) => {
                      setFocusArea(event.target.value);
                      setValue("focusArea", event.target.value);
                    }}
                  >
                    <option selected={true} value="">
                      {" "}
                      --Select An Option--{" "}
                    </option>
                    {props?.focusAreas?.focusAreaList?.map((fa: any) => {
                      return (
                        <option key={fa} value={fa}>
                          {fa}
                        </option>
                      );
                    })}
                  </Select>
                }
                rules={{
                  required: "Focus area is required",
                }}
              />
              {errors?.focusArea?.message && (
                <span
                  className="reg-form-error-container"
                  style={{ color: "red" }}
                >
                  {errors?.focusArea?.message}
                </span>
              )}
            </div>
            <Divider />
          </CardItem>

          <CardItem className="space-request-questions-card-item">
            <div className="space-request-questions-card-question">
              3. Who will be the main contact for the event ?
              <Tooltip 
                title={
                  <div className="tooltip-contacts-wrapper">
                  <span className="tooltip-contacts">
                    Only the <strong>Organization Contact(s)</strong> are able to modify or cancel reservations in the Spark Good Space Request Tool at <a href="https://www.walmart.com/nonprofits" target="_blank" rel="noopener noreferrer">www.walmart.com/nonprofits</a>. If you need to update the organization contact, please make changes to your DEED account. 
                  </span> 
                  <br/>
                  <span className="tooltip-contacts"> 
                    <strong>Event Contact(s)</strong> listed for the event are only able to check-in and check-out for the event and cannot make modifications to the event and cancel the reservation. 
                  </span>
                  <br/>
                  <span className="tooltip-contacts"> 
                    Note : There is a limit of not more than 15 members of any group participating in an event in front of a store or club at any one time.
                  </span>
                  </div>
                }
                placement="right"
              >
                <span>
                <InfoCircle className="icon-info-contacts"/>
                </span>
              </Tooltip>
            </div>
            <div className="contact-label">Contact</div>
            <div className="space-request-questions-card-answer">
            <Controller
                control={control}
                name="contact"
                data-testid="contact"
                render={() => (
                  <Autocomplete
                    multiple
                    value={contact}
                    options={combinedOptions || []}
                    disableCloseOnSelect
                    onChange={(event:any, newValue) => {
                      eventData=event;
                      if (newValue.length <= 15) {
                        setContact(newValue);
                        setValue("contact",newValue);
                      } else {
                        newValue?.pop(); 
                        return;
                      }
                    }}
                    getOptionLabel={(option) => {
                      return `${option?.firstName} ${option?.lastName}`;
                    }}
                    getOptionSelected={(option, value) => option.id === value.id}
                    renderOption={(props, option, { selected }) => {
                      return (
                        <div className="contacts-multiselect-dropdown">
                        <li className="local-multicontacts-list" {...props}>
                          <Checkbox
                            className="multiselect-box-question-form"
                            checked={selected}
                          />
                        {userContext?.organization?.userContactDetails?.cid === option?.cid
                        ? "Myself (Organization Contact)"
                        : (secondaryContacts && secondaryContacts?.uuid === option?.uuid
                        ? `${toCapitalize(option?.firstName)} ${toCapitalize(option?.lastName)} (Organization Contact)`
                        : `${toCapitalize(option?.firstName)} ${toCapitalize(option?.lastName)} (Event Contact)`
                        )
                    }
                          </li>
                          </div>
                      );
                    }}
                    renderInput={(params: any) => (
                      <TextField
                        className="text-field-contact"
                        {...params}
                        label="--Select An Option--"
                      />
                    )}
                    className="multi-select-contact-list"
                  />
                )}
                rules={{
                  required: "Contact details required",
                }}
              />
              {errors?.contact?.message && (
                <span
                  className="reg-form-error-container"
                  style={{ color: "red" }}
                >
                  {errors?.contact?.message}
                </span>
              )}
            </div>
            <Divider />
          </CardItem>
          <CardItem>
            <div
              className="secondary-contact-add-section"
              onClick={() => setShowCustomUserPopUp(true)}
            >
              <Plus
                style={{ fill: "#0072ea", fontSize: "2.5rem", margin: "auto" }}
              />
              <div className="secondary-contact-add">Add new event contact</div>
            </div>
          </CardItem>
          <CardItem className="space-request-questions-card-item">
            <div className="space-request-questions-card-question">
              4. Please write a short description about your event. (E.g. What
              is the purpose, how many people will be attending and why you
              would like to use our outside space?)
            </div>
            <div className="space-request-questions-card-answer">
              {/*  */}

              <Controller
                control={control}
                name="description"
                defaultValue={""}
                data-testid="description"
                as={
                  <>
                    <b>
                      <div className="space-request-questions-card-answer-text">
                        Event Description
                      </div>
                    </b>
                    <textarea
                      value={description}
                      required={true}
                      rows={6}
                      placeholder={"Please input alphanumeric characters only"}
                      onChange={(event: any) => handleOnChangeTextarea(event)}
                    />
                  </>
                }
                rules={{
                  required: "Description is required",
                }}
              />
              {errors?.description?.message && (
                <span
                  className="reg-form-error-container"
                  style={{ color: "red" }}
                >
                  {errors?.description?.message}
                </span>
              )}

              {/*  */}
            </div>
            <CardItem className="space-request-questions-card-item">
              <div className="space-request-questions-card-button-grid space-request-questions-desktop-button-section">
                <div />
                <div className="space-request-questions-card-button">
                  <Button
                    className="space-button"
                    type="submit"
                    variant="primary"
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </CardItem>
            <Divider />
          </CardItem>
          <Divider />
          <div className="phone-buffer-section" />
        </Card>
        <CardItem className="space-request-questions-card-item space-request-questions-phone-button-section">
          <div className="space-request-questions-card-button">
            <Button
              className="space-request-questions-phone-button space-button"
              type="submit"
              variant="primary"
            >
              Continue
            </Button>
          </div>
        </CardItem>
      </form>
      {showCustomUserPopUp && (
        <CustomUserForm
          isOpen={true}
          setIsOpen={setShowCustomUserPopUp}
          clearContacts={clearContacts}
          customContact={props.customContact}
          setCustomContact={props.setCustomContact}
          setShowCustomUserPopUp={setShowCustomUserPopUp}
          refetch={refetch}
          setValue={setValue}
          setContact={setContact}
          secondaryContacts={secondaryContacts}
          setCustomContactSelected={props.setCustomContactSelected}
        />
      )}
    </>
  );
};

export default QuestionForm;

const validateDuplicateEmail = (
  users: any[],
  secondaryContacts: any[],
  email: string
) => {
  if (secondaryContacts.length > 0) {
    return (
      !users.map((z) => z.email).includes(email) &&
      !secondaryContacts?.map((z) => z.email).includes(email)
    );
  } else {
    return !users.map((z) => z.email).includes(email);
  }
};

export const CustomUserForm = (props: any) => {
  const userContext: any = useContext(UserContext);
  const mutation = useMutation((data: any) =>
    service.saveSecondaryContact({ orgId: data.orgId, data: data.data })
  );
  const [customContact] = useState(props?.customContact);
  const { control, handleSubmit, errors, getValues, setValue } = useForm({
    mode: "onChange",
    defaultValues: {
      firstName: customContact?.firstName ? customContact?.firstName : "",
      lastName: customContact?.lastName ? customContact?.lastName : "",
      email: customContact?.email ? customContact?.email : "",
      phoneNumber: customContact?.phoneNumber ? customContact?.phoneNumber : "",
      permissionToEmail: true,
    },
  });

  useEffect(() => {
    if (mutation.isSuccess) {
      props.setCustomContact(getValues());
      props.setCustomContactSelected(true);
      props.setContact(
        `${getValues().firstName}####${getValues().lastName}####sc`
      );
      props.setValue(
        "contact",
        `${getValues().firstName}####${getValues().lastName}####sc`
      );
      props.setShowCustomUserPopUp(false);
      props.refetch(
        ["getSecondaryContact", { orgId: userContext?.organization?.id }],
        service.getSecondaryContact,
        { refetchOnWindowFocus: false }
      );
    }
  }, [mutation.isSuccess]);

  const onSubmit = () => {
    mutation.mutate({
      orgId: userContext?.organization?.id,
      data: getValues(),
    });
    setValue("firstName", "");
    setValue("lastName", "");
    setValue("email", "");
    setValue("phoneNumber", "");
  };

  return (
    <LDModal
      title="Event Contact Information"
      size="small"
      onClose={() => {
        props.setIsOpen(false);
        setValue("firstName", "");
        setValue("lastName", "");
        setValue("email", "");
        setValue("phoneNumber", "");
      }}
      style={{ width: "18vw !important", height: "40vh !important" }}
      isOpen={props.isOpen}
    >
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <Controller
              control={control}
              name="firstName"
              defaultValue={""}
              data-testid="firstName"
              as={
                <LDTextField label="First name" required={true} type="text" />
              }
              rules={{
                required: "First name is required",
                validate: (value: any) => {
                  return isNotEmpty(value, "First name is required");
                },
              }}
            />
            {errors?.firstName?.message && (
              <span
                className="reg-form-error-container"
                style={{ color: "red" }}
              >
                {errors?.firstName?.message}
              </span>
            )}
          </div>

          <div>
            <Controller
              control={control}
              name="lastName"
              defaultValue={""}
              data-testid="lastName"
              as={<LDTextField label="Last name" required={true} type="text" />}
              rules={{
                required: "Last name is required",
                validate: (value: any) => {
                  return isNotEmpty(value, "Last name is required");
                },
              }}
            />
            {errors?.lastName?.message && (
              <span
                className="reg-form-error-container"
                style={{ color: "red" }}
              >
                {errors?.lastName?.message}
              </span>
            )}
          </div>

          <div>
            <Controller
              control={control}
              name="email"
              defaultValue={""}
              data-testid="email"
              as={
                <LDTextField
                  label="Email"
                  type="text"
                  required={true}
                  value={props.email2}
                />
              }
              rules={{
                required: "Email is required",
                validate: (value: any) => {
                  const { orgContactList } = userContext?.organization ?? {};
                  const secondaryContacts = Array.isArray(
                    props.secondaryContacts
                  )
                    ? props.secondaryContacts
                    : [];
                  if (!validateEmail(value)) {
                    return "Invalid email";
                  } else if (
                    !validateDuplicateEmail(
                      orgContactList,
                      secondaryContacts,
                      value
                    )
                  ) {
                    return "Email already exist";
                  } else {
                    return true;
                  }
                },
              }}
            />
            {errors?.email?.message && (
              <span
                className="reg-form-error-container"
                style={{ color: "red" }}
              >
                {errors?.email?.message}
              </span>
            )}
          </div>
          <div>
            <Controller
              control={control}
              name="phoneNumber"
              defaultValue={""}
              data-testid="phoneNumber"
              as={
                <LDTextField
                  label="Mobile Phone Number"
                  value={props.phone2}
                  required={true}
                  type="text"
                />
              }
              rules={{
                required: "Mobile Phone Number is required",
                validate: (value: any) => {
                  return validatePhoneNumber(value, setValue);
                },
              }}
            />
            {errors?.phoneNumber?.message && (
              <span
                className="reg-form-error-container"
                style={{ color: "red" }}
              >
                {errors?.phoneNumber?.message}
              </span>
            )}
          </div>
          <div className="info-card-buttons-spaced">
            <Button
              onClick={() => {
                props.setIsOpen(false);
              }}
              disabled={mutation.isLoading}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={mutation.isLoading}
              variant="primary"
            >
              {mutation.isLoading ? "Saving" : "Save"}
            </Button>
          </div>
        </form>
      </div>
    </LDModal>
  );
};
n