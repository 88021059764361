import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import withRouter from "../../../polyfils/customRouter";
import ThankyouComponent from "../../common/ThankyouComponent";
import { getDefaultBreadCrumbArray } from './BreadCrumbRouting';
import { spacePageType } from "../gdmsSpaceDonation/SpaceInternationalDataFunctions";
import { getLangObject } from "../../common/utils";
import { DONATION_TYPE_VALUES } from "../../../constants";
import { servicePageType } from "../serviceDonation/serviceDonationDataFunctions";

export interface OwnProps {
    history: any;
    donationId: any;
    donationCategory: any;
}

const DonationFeedbackTemplate: FC<OwnProps> = ({ history, donationCategory }) => {
    const { t } = useTranslation();
    const { state } = useLocation();
    const langObjectPath = getLangObject(donationCategory);
    const goToPath = donationCategory === DONATION_TYPE_VALUES.SPACE_INTERNATIONAL ? spacePageType : servicePageType;
    const breadcrumbsData = [
        ...getDefaultBreadCrumbArray(langObjectPath, donationCategory),
        {
            name: `${t(`${langObjectPath}.nav.donationId`)}${state.donationId ?? ""}`,
            active: true,
            applyFormat: false,
        },
    ];
    const tabSelected = donationCategory === DONATION_TYPE_VALUES.SPACE_INTERNATIONAL ? 1 : 0;
    let subject = t(`${langObjectPath}.thankYou.subject`);
    let body = t(`${langObjectPath}.thankYou.body`);
    if (state.status === "cancel") {
        subject = t(`${langObjectPath}.thankYou.cancelSubject`);
        body = t(`${langObjectPath}.thankYou.cancelBody`);
    } else if (state.status === "accept") {
        subject = t(`${langObjectPath}.thankYou.acceptSubject`);
        body = t(`${langObjectPath}.thankYou.acceptBody`);
    }
    return (
        <div style={{ paddingTop: "2px" }}>
            <ThankyouComponent breadcrumbsData={breadcrumbsData}
                history={history}
                thankyouSubject={subject}
                thankyouHeading={body}
                thankyouButton={t(`${langObjectPath}.thankYou.button`)}
                contextUrl={goToPath}
                tabSelected={tabSelected}
            ></ThankyouComponent>
        </div>
    );
};
export default withRouter(DonationFeedbackTemplate);
