import * as React from 'react';
import SubmissionGridComponent from 'dynamic-form-components/lib/components/SubmissionGridComponent';
import { RouteComponentProps } from 'src/polyfils/customRouter';
import withRouter from 'src/polyfils/customRouter';
import { Button, createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { GlobalDonationState, UiHubProps, Privileges } from './../../../models/globalDonationState';
import ApplicationDataService from 'dynamic-form-components/lib/service';
import { SubmissionState } from 'dynamic-form-components/lib/models/ApplicationState';
import { submissionData } from './../../../state/submission/submissionDataActions';
import { selectedData } from './../../../state/selectedData/selectedDataActions';
import { GetColumnConfigPayload } from 'dynamic-form-components/lib/models/SubmissionModel';
import { getColumnConfig } from 'dynamic-form-components/lib/state/submission/submissionDataActions';
import { downloadFileData } from './../../../state/downloadFile/downloadFileDataActions';
import { contextPath, searchField } from '../../../constants';
import { saveFormDataFailure } from 'dynamic-form-components/lib/state/form/formDataActions';
import GlobalDonationService, { Service } from '../../../service';
import { donationsStatusText } from './../../../constants';
import { gridTranslations } from 'src/static/GridTranslations';
import LoaderComponent from 'src/components/LoaderComponent';
import { getDateOnlyForLocale, getFormattedDate } from '../../common/DateUtils';
import analytics from '../../../adobe-analytics/analytics';
import { getFilter } from '../../../components/common/utils';
import { ANALYTICS } from '../../../adobe-analytics/analytics-dataelemets';
const config = require(`./../../../config.${process.env.NODE_ENV}.json`);
export let service: Service = GlobalDonationService;
const { getLoggedInUserDetail } = service;
export interface OwnProps {
    classes?: any;
    appi18n: any;
    formLink: string;
    remoteDataUri?: string;
    privileges: Privileges;
    uiHubProps: UiHubProps;
    showViewDiagramDialog?: boolean;
    loggedInUserData: any;
}

export interface DispatchProps {
    getSubmissions: (data: any) => void;
    addSelectedDataToStore: (data: any) => void;
    getColumnConfig: (data: any) => void;
    downloadPdf: (data: any) => void;
    updateFormSuccessStatus: () => void;
}

export interface StateProps {
    columns: any[];
    selectedLang: string;
    submissionState: SubmissionState;
    downloadFileState: any
}

const styles = {
    buttonStyles: {
        marginBottom: 20
    }
}

const appi18n: any = {
    ...gridTranslations,
    gridTitle: {
        en: 'List of Requests',
        es: 'Lista de solicitudes',
        fr: 'Liste des demandes',
        ja: 'リクエスト一覧',
        zh: '请求清单'
    },
    createNewBtnLable: {
        en: 'Create New',
        es: 'Crear nueva',
        fr: 'Créer un nouveau',
        ja: '新しく作る',
        zh: '创建新的'
    },
    backBtnLabel: {
        en: 'Back',
        es: 'atrás',
        fr: 'Retour',
        ja: 'バック',
        zh: '背部'
    },
    projectPdfLink: {
        en: 'Project Pdf',
        es: 'Proyecto Pdf',
        fr: 'Projet Pdf',
        ja: 'プロジェクトPDF',
        zh: '项目PDF'
    },
    donationRequests: {
        en: 'Donation Requests',
        es: 'Solicitudes de donación',
        fr: 'Demandes de dons',
        ja: '寄付のリクエスト',
        zh: '捐款要求'
    }
}
const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#4caf50',
        },
        secondary: {
            main: '#ff9100',
        },
    },
    overrides: {
        MuiPaper: {
            root: {
                width: 'auto !important'
            }
        },
        MuiSelect: {
            selectMenu: {
                minWidth: '150px',
                whiteSpace: 'pre-wrap'
            }
        },
        MuiInputBase: {
            input: {
                minWidth: '80px'
            }
        }
    }
});
class CommunityGrantGrid extends React.PureComponent<OwnProps & RouteComponentProps & StateProps & DispatchProps> {
    public componentDidMount() {
        analytics.trackPageInfoGdmsExternal(ANALYTICS.communityGrant.dashboardPage);
    }
    public state = {
        submissionForEdit: {},
        formAlias: 'communityGrant',
        listAlias: 'communityGrant',
        donationType: 'COMMUNITY_GRANT',
        columns: [],
        options: {}
    }

    private type: string | null = 'community-grants';
    private formOptions: any;
    private donationsStatusText = donationsStatusText[this.state.donationType];

    public UNSAFE_componentWillMount() {
        const payload = {
            value: {},
            key: 'communityGrant'
        }
        this.props.addSelectedDataToStore(payload);
        this.props.getColumnConfig({
            listAlias: this.state.listAlias,
        });
        this.props.updateFormSuccessStatus();
        this.setState({
            options: {
                exportButton: false,
                debounceInterval: 400,
                minBodyHeight: '300px',
                maxBodyHeight: '721px',
                headerStyle: {
                    backgroundColor: '#ffffff',
                    fontFamily: 'Bogle',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: 'normal',
                    letterSpacing: '0.09px',
                    color: '#041f41',
                },
                rowStyle: {
                    backgroundColor: '#ffffff',
                    fontFamily: 'Bogle',
                    fontSize: '14px',
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: 'normal',
                    letterSpacing: '0.09px',
                },
                filtering: true,
                pageSizeOptions: [10, 20, 30, 40, 50, 100, 200]
            }
        })
    }
    public UNSAFE_componentWillReceiveProps(newProps: StateProps) {
        this.formOptions = {
            noAlerts: true,
            language: newProps.selectedLang
        }
        const columns: any = [];
        if ((newProps.columns !== this.props.columns && newProps.columns.length > 0) ||
            (newProps.selectedLang !== this.props.selectedLang)) {
            newProps.columns.map((column: any) => {
                let customCoulmns: any = {};
                if (column.field === 'donation.id') {
                    customCoulmns = {
                        ...column,
                        filtering: true,
                        render: (rowData: any) => {
                            return <button className='btn-link'
                                onClick={() => this.viewDonationDetails(rowData)}>{rowData.donation.id}</button>
                        }
                    }
                }
                if (column.field === 'donation.currentStatusCode') {
                    customCoulmns = {
                        ...column,
                        'lookup': this.donationsStatusText[newProps.selectedLang],
                        filtering: true,
                    }
                }
                if (column.field === 'donation.audit.createdDtm') {
                    customCoulmns = {
                        ...column,
                        render: (rowData: any) => {
                            return getFormattedDate(rowData.donation.audit.createdDtm)
                        }
                    }
                }

                if (column.field === 'lastActionTaken') {
                    customCoulmns = {
                        ...column,
                        filtering: false,
                        render: (rowData: any) => {
                            if (rowData.donation.statuses && rowData.donation.statuses.length > 0) {
                                const latestStatus: any = rowData.donation.statuses.reduce((a: any, b: any) =>
                                    (a.changeDate > b.changeDate ? a : b));
                                return <span>{`${latestStatus.action}`}</span>
                            } else {
                                return <span>NA</span>
                            }
                        }
                    }
                }
                /* istanbul ignore next */
                if (column.field === 'lastActionDate') {
                    customCoulmns = {
                        ...column,
                        filtering: false,
                        render: (rowData: any) => {
                            if (rowData.donation.statuses && rowData.donation.statuses.length > 0) {
                                const latestStatus: any = rowData.donation.statuses.reduce((a: any, b: any) =>
                                    (a.changeDate > b.changeDate ? a : b));
                                return <span>{`${getDateOnlyForLocale(latestStatus.changeDate)}`}</span>
                            } else {
                                return <span>NA</span>
                            }
                        }
                    }
                }
                if (column.field === 'projectLinks') {
                    customCoulmns = {
                        ...column,
                        filtering: false,
                        render: (rowData: any) => {
                            return <button className='btn-link' onClick={() => this.downloadPdf(rowData)}>
                                {appi18n.projectPdfLink[newProps.selectedLang]}
                            </button>
                        }
                    }
                }
                columns.push({
                    ...column,
                    ...customCoulmns
                })
            })
            this.setState({ columns })
        }
    }
    private downloadPdf = (data: any) => {
        const payload = {
            ...data,
            donationType: this.state.donationType,
            langCode: this.props.selectedLang
        }
        this.props.downloadPdf(payload);
    }
    public render() {

        let createBtn: any;
        createBtn = <Button className={`back-btn ${this.props.classes.buttonStyles}`}
            variant='contained' color='primary'
            onClick={this.handleCreateDonation}>
            {appi18n.createNewBtnLable[this.props.selectedLang]}
        </Button>

        return <>
            {createBtn}
            <MuiThemeProvider theme={theme}>
                <SubmissionGridComponent
                    title={appi18n.donationRequests[this.props.selectedLang]}
                    formAlias={this.state.formAlias}
                    listAlias={this.state.formAlias}
                    // remoteDataUrl={remoteDataUrl}
                    columns={this.state.columns}
                    i18n={appi18n}
                    privileges={this.props.privileges}
                    formOptions={this.formOptions}
                    options={this.state.options}
                    selectedLanguage={this.props.selectedLang}
                    remoteDataFunc={this.getData}
                />
            </MuiThemeProvider>
            {this.props.downloadFileState.downloadFileLoading ? <LoaderComponent fullScreen={true} /> : <></>}
        </>
    };
    private getData = (query: any) => {
        const filtersRgex: any = []
        let regexQuery: any = '';
        query.filters.forEach((filter: any) => {
            let fieldName = filter.column.field.replace('donation.', '');
            fieldName = searchField[fieldName] ? searchField[fieldName] : fieldName;
            if (fieldName === 'status') {
                filtersRgex.push(getFilter(fieldName, filter.value, 'IN', ''))
            } else {
                if (fieldName === '_id') {
                    // tslint:disable-next-line: radix
                    filtersRgex.push(getFilter(fieldName, parseInt(filter.value), 'CONTAINS', ''))
                } else {
                    filtersRgex.push(getFilter(fieldName, filter.value, 'CONTAINS', ''))
                }
            }
        });

        if (query.search) {
            // tslint:disable-next-line: radix
            const numericSearchTxt = !isNaN(parseInt(query.search)) ? parseInt(query.search) : query.search;
            regexQuery = {
                'globalSearchFilters': [
                    getFilter('_id', numericSearchTxt, 'CONTAINS', ''),
                ]
            }
        }
        const searchQuery = {
            'defaultFilters': [
                getFilter('type', this.state.donationType, 'EQUALS', ''),
                ...filtersRgex
            ],
            ...regexQuery,
            orderBy: [
                {
                    orderByField: 'audit.createdDtm',
                    orderDir: 'DESC'
                }
            ],
            pageNumber: query.page + 1,
            pageSize: query.pageSize
        }
        const url = `${config.gdmsServiceUrl}/community-grant/dashboard`;
        if (this.props.loggedInUserData && Object.keys(this.props.loggedInUserData).length === 0) {
            // @ts-ignore
            return getLoggedInUserDetail().then((resp) => {
                return ApplicationDataService.getData(url, searchQuery).catch((e: any) => {
                    if (e.response && e.response.status === 401) {
                        window.location.href = config.pingfedUrl;
                    }
                })
            })
        } else {
            return ApplicationDataService.getData(url, searchQuery).catch((e: any) => {
                if (e.response && e.response.status === 401) {
                    window.location.href = config.pingfedUrl;
                }
            })
        }
    }
    private viewDonationDetails = (rowData: any) => {
        const payload = {
            value: {
                ...rowData.donation.donationDetail,
                id: rowData.donation.id,
                readOnly: true,
            },
            key: 'communityGrant'
        }
        this.props.addSelectedDataToStore(payload);
        this.props.history.push(`${contextPath}/donation/view/community-grants?type=${this.type}`);
    }
    private handleCreateDonation = () => {
        this.props.history.push(`${contextPath}/donation/community-grants?type=${this.type}`);
    }
}

const mapStateToProps = (state: GlobalDonationState): StateProps => ({
    columns: state.submissionState.columnConfig,
    selectedLang: state.selectedLang,
    submissionState: state.submissionState,
    downloadFileState: state.downloadFileState
});

const mapDispatchToProps = (dispatch: React.Dispatch<any>): DispatchProps => ({
    getSubmissions: (data) => dispatch(submissionData(data)),
    addSelectedDataToStore: (data: any) => dispatch(selectedData(data)),
    getColumnConfig: (data: GetColumnConfigPayload) => dispatch(getColumnConfig(data)),
    downloadPdf: (data: any) => dispatch(downloadFileData(data)),
    updateFormSuccessStatus: () => dispatch(saveFormDataFailure(''))
});

export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(CommunityGrantGrid)));
