import { Avatar, Badge, Drawer, Hidden, Theme, Typography, withStyles } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { WalmartIcon } from 'dynamic-form-components/lib/components/icon/WalmartLogo';
import ErrorSnackbarComponent from 'dynamic-form-components/lib/components/menu/ErrorSnackbarComponent';
import SuccessSnackBarComponent from 'dynamic-form-components/lib/components/menu/SuccessSnackBarComponent';
import { getEncryptedString } from 'dynamic-form-components/lib/crypto';
import * as React from 'react';
import { connect } from 'react-redux';
import { Outlet } from 'react-router';
import { RouteComponentProps } from 'src/polyfils/customRouter';
import withRouter from 'src/polyfils/customRouter';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { loggedInUserData } from 'src/state/loggedInUser/loggedInUserActions';
import { cleareLogoutState, logoutUser } from 'src/state/logout/logoutUserActions';

import { countryCodeToMarketCode, landigPageUrl } from '../../constants';
import { viewOrDownloadFile } from '../../state/ManageWorkflow/fileActions';
import {
    changeSelectedUser,
    checkFirstTimeLogIn,
    saveOrgUserPassword,
} from '../../state/orgUserManagement/orgUserManagementActions';
import { selectedData } from '../../state/selectedData/selectedDataActions';
import OrgUserResetTempPasswordDialog from '../orgUserManagement/OrgUserResetTempPasswordDialog';
import { GlobalDonationState } from './../../models/globalDonationState';
import AppBarComponent from './AppBarComponent';
import DynamicSideBar from './DynamicSideBar';
import styles from './ThemeStyle';
import Timer from './Timer';
import asdLogo from '../../resources/asda_green.svg'


const config = require(`./../../config.${process.env.NODE_ENV}.json`);


/* tslint:disable */
export interface OwnProps {
    classes: any;
    theme: Theme;
    container: any;
}

export interface DispatchProps {
    getLoggedInUserDetail: () => void;
    logout: () => void;
    saveUserPassword: (data: any) => void;
    cleareLogoutState: () => void;
    downloadFile: (data: any) => void;
    checkFirstTimeLoginOfUser: () => void;
    setUserStateToInitial: () => void;
    addSelectedDataToStore: (data: any) => void;
}

export const getQueryParam = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    return params.get('type');
}
const appi18n: any = {
    logoutTxt: {
        en: 'Logout',
        es: 'Cerrar sesión',
        fr: 'Se déconnecter',
        ja: 'ログアウト',
        zh: '登出'
    },
    privacyPolicy: {
        en: 'Privacy Policy',
        es: 'Política de privacidad',
        fr: 'Politique de confidentialité',
        ja: '個人情報保護方針',
        zh: '隱私政策'
    }
}

class ResponsiveMenu extends React.Component<OwnProps & DispatchProps & RouteComponentProps & GlobalDonationState> {
    public state = {
        mobileOpen: false,
        mobileMoreAnchorEl: null,
        anchorEl: null,
        anchorNotificationEl: null,
        notificationCount: '0',
        showNotificationExhaust: false,
        pageLoading: false,
    };



    public submission = {
        data: {
            storeNumber: 4126
        }
    };

    public componentWillMount() {
        this.props.getLoggedInUserDetail();
        this.props.cleareLogoutState();
        this.props.checkFirstTimeLoginOfUser();
    }

    public componentWillReceiveProps(newProps: any) {
        if (newProps.orgUserManagementState.savePasswordSuccessful !== this.props.orgUserManagementState.savePasswordSuccessful
            && newProps.orgUserManagementState.savePasswordSuccessful) {
            this.props.setUserStateToInitial();
            this.handleSignout();
        }
    }

    public submit(data: any) {
        console.log('data', data);
    }


    public render() {
        // @ts-ignore
        // window.lang = this.props.selectedLang;
        const { classes, theme, location } = this.props;
        window.countryCode = this.props.loggedInUserState && this.props.loggedInUserState.userDetail &&
            this.props.loggedInUserState && this.props.loggedInUserState.userDetail.countryCode;
        const { anchorEl, mobileMoreAnchorEl, anchorNotificationEl } = this.state;
        const isMenuOpen = Boolean(anchorEl);
        const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
        const isNotificationOpen = Boolean(anchorNotificationEl);
        const userName = this.props.loggedInUserState && this.props.loggedInUserState.userDetail &&
            this.props.loggedInUserState.userDetail.orgLegalName;
        const marketCode = this.props.loggedInUserState && this.props.loggedInUserState.userDetail &&
            this.props.loggedInUserState.userDetail.countryCode;

        if (this.props.logoutState.logoutLoadingSuccess) {
            this.redirectLandingPage()
        }

        const renderMenu = (
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={isMenuOpen}
                onClose={this.handleMenuClose}
            >
                {/* <MenuItem onClick={this.viewProfile}>View Profile</MenuItem> */}
                <MenuItem onClick={this.handleSignout}>{appi18n.logoutTxt[this.props.selectedLang]}</MenuItem>
            </Menu>
        );
        const renderMobileMenu = (<>
            <Menu
                anchorEl={mobileMoreAnchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={isMobileMenuOpen}
                onClose={this.handleMobileMenuClose}
            >
                <MenuItem onClick={this.handleProfileMenuOpen}>
                    <IconButton color='inherit'>
                        <Avatar className={classes.orangeAvatar}>
                            {userName}
                        </Avatar>
                    </IconButton>
                </MenuItem>
                <MenuItem hidden={!this.state.anchorEl}>
                    <IconButton
                        aria-haspopup='true'
                        aria-owns={isNotificationOpen ? 'material-appbar' : undefined}
                        color='inherit'
                        onClick={this.handleNotificationMenuOpen}
                    >
                        <Badge badgeContent={this.state.notificationCount} color='secondary'>
                            <NotificationsIcon />
                        </Badge>
                    </IconButton>
                </MenuItem>
            </Menu></>
        );

        const drawer = (
            <div>
                <div className={`${classes.toolbar} ${classes.logo}`}>
                    <Typography color='inherit' variant='h6'>
                        {marketCode === 'GB' ?
                            // eslint-disable-next-line jsx-a11y/alt-text
                            <img style={{ height: '25px' }} src={asdLogo} />
                            : <>GDMS <WalmartIcon /></>}
                    </Typography>
                </div>
                <Divider />
                <List>
                    <DynamicSideBar handleDrawerClose={this.handleDrawerClose} />
                </List>
                <Divider />
                <List>
                    {/* secondary menus  */}
                </List>
            </div>
        );

        return (
            <div className={classes.root}>
                <CssBaseline />
                <AppBarComponent isMenuOpen={isMenuOpen}
                    mobileOpen={this.state.mobileOpen}
                    mobileMoreAnchorEl={this.state.mobileMoreAnchorEl}
                    anchorEl={this.state.anchorEl}
                    anchorNotificationEl={this.state.anchorNotificationEl}
                    notificationCount={this.state.notificationCount}
                    showNotificationExhaust={this.state.showNotificationExhaust}
                    pageLoading={this.state.pageLoading}
                    handleMobileMenuOpen={this.handleMobileMenuOpen}
                    handleDrawerToggle={this.handleDrawerToggle}
                    handleNotificationMenuOpen={this.handleNotificationMenuOpen}
                    handleProfileMenuOpen={this.handleProfileMenuOpen}
                />
                {renderMenu}
                {renderMobileMenu}
                {(location.pathname !== '/Public/landingPage' &&
                    location.pathname !== '/login' &&
                    location.pathname !== '/Public/forgotPassword' &&
                    location.pathname !== '/Public/register/eligibilityQuiz') && <nav className={classes.drawer}>
                        <Hidden smUp={true} implementation='css'>
                            <Drawer
                                container={this.props.container}
                                variant='temporary'
                                anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                                open={this.state.mobileOpen}
                                onClose={this.handleDrawerToggle}
                                classes={{
                                    paper: classes.drawerPaper,
                                }}
                            >
                                {drawer}
                            </Drawer>
                        </Hidden>
                        <Hidden xsDown={true} implementation='css'>
                            <Drawer
                                classes={{
                                    paper: classes.drawerPaper,
                                }}
                                variant='permanent'
                                open={true}
                            >
                                {drawer}
                            </Drawer>
                        </Hidden>
                    </nav>}
                <div className={classes.loadWrapper} hidden={!this.state.pageLoading}>
                    <div className={classes.loader}>
                        <ScaleLoader
                            color={'#041E42'}
                            loading={true}
                        />
                    </div>
                </div>
                <main className={`${classes.content} ${this.state.pageLoading ? classes.pageLoading : ''}`}
                    style={{ overflowX: 'auto', overflowY: 'auto' }}>
                    <Outlet></Outlet>
                </main>
                <OrgUserResetTempPasswordDialog
                    showDialog={this.props.orgUserManagementState.firstTimeLogInSuccess}
                    isAsdaTheme={false}
                    saveNewPassword={this.props.saveUserPassword}
                    loading={this.props.orgUserManagementState.savePasswordLoading}
                />
                <Timer selectedLang={this.props.selectedLang} />
                <ErrorSnackbarComponent isError={this.props.globalDonationDataLoadingFailure && false} classes={classes}
                    message={this.props.notificationMsg || `Failed!`} />
                <ErrorSnackbarComponent isError={this.props.applicationDataLoadingFailure && false} classes={classes}
                    message={this.props.message || `Failed!`} />
                <SuccessSnackBarComponent isSuccess={this.props.applicationDataLoadingSuccess}
                    message={this.props.message || `Success!`} />
                <div className={`app-footer ${marketCode === 'GB' ? 'uk-bg' : ''}`}>
                    <a style={{ marginLeft: '100px' }} href='javascript:;' title='Privacy Policy'
                        onClick={this.handleDownload}>{appi18n.privacyPolicy[this.props.selectedLang]}</a>
                    <p>© Walmart Stores, Inc.</p>
                </div>
            </div>
        );
    }

    public handleDownload = () => {
        const downloadDirectory = countryCodeToMarketCode[window.countryCode];
        const fileInfo = {
            templateKey: 'PRIVACY_POLICY',
            downloadDir: downloadDirectory
        }
        const encryptedFileInfo = encodeURIComponent(getEncryptedString(fileInfo));
        const dataUri = `public/files/privacy-policy?fileInfo=${encryptedFileInfo}`;
        this.props.downloadFile({ name: 'privacy-policy.docx', type: 'downloadFiles', actionUrl: `${config.gdmsServiceUrl}/${dataUri}` });
    }

    private handleDrawerToggle = () => {
        this.setState(() => ({ mobileOpen: !this.state.mobileOpen }));
    };

    /*     private onFormSubmit = (submission: any) => {
            console.log(submission);
        } */

    private handleDrawerClose = () => {
        this.setState(() => ({ mobileOpen: false }));
    };

    private handleProfileMenuOpen = (event: any) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    private handleNotificationMenuOpen = (event: any) => {
        this.setState({ anchorNotificationEl: event.currentTarget });
    };

    private handleMenuClose = () => {
        this.setState({ anchorEl: null });
        this.handleMobileMenuClose();
    };

    /* private viewProfile = () => {
        this.props.history.push('/');
        this.handleMenuClose();
    } */

    private handleSignout = () => {
        this.props.logout()
        this.handleMenuClose();
    }

    private redirectLandingPage = () => {
        // this.props.history.push(`${landigPageUrl}`);
        window.location.href = `${landigPageUrl}`
    }

    private handleMobileMenuClose = () => {
        this.setState({ mobileMoreAnchorEl: null });
    };

    private handleMobileMenuOpen = (event: any) => {
        this.setState({ mobileMoreAnchorEl: event.currentTarget });
    };
}

const mapStateToProps = (state: GlobalDonationState): GlobalDonationState => {
    return state;
};

const mapDispatchToProps = (dispatch: React.Dispatch<any>): DispatchProps => ({
    getLoggedInUserDetail: () => dispatch(loggedInUserData()),
    saveUserPassword: (data) => dispatch(saveOrgUserPassword(data)),
    checkFirstTimeLoginOfUser: () => dispatch(checkFirstTimeLogIn()),
    logout: () => dispatch(logoutUser()),
    cleareLogoutState: () => dispatch(cleareLogoutState()),
    downloadFile: (data) => dispatch(viewOrDownloadFile(data)),
    setUserStateToInitial: () => dispatch(changeSelectedUser({})),
    addSelectedDataToStore: (data: any) => dispatch(selectedData(data))
});

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(ResponsiveMenu)));
