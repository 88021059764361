const tester = /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
export const validateEmail = (email: string) => {
    if (!email) {
        return false;
    }
    const emailParts = email.split('@');
    if (emailParts.length !== 2) {
        return false
    }
    const account = emailParts[0];
    const address = emailParts[1];
    if (account.length > 64) {
        return false
    } else if (address.length > 255) {
        return false
    }
    const domainParts = address.split('.');
    if (domainParts.some((part) => {
        return part.length > 63;
    })) {
        return false
    }
    if (!tester.test(email)) {
        return false;
    }
    return true;
};

export const isNotEmpty = (value: string, errorMessage: string) => {
    const pattern = /\S+/;
    return pattern.test(value) ? true : errorMessage || 'Field should not be empty';
}

export const isOnlyNumbers = (value: string, errorMessage: string) => {
    const pattern = /^\d+$/;
    return pattern.test(value) ? true : errorMessage || 'Field should have only numbers';
}

export const validateWebsiteUrl = (value: string) => {
    const pattern = new RegExp(
        '^([a-zA-Z]+:\\/\\/)?' + // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
          '(\\#[-a-z\\d_]*)?$', // fragment locator
        'i'
      );
      return pattern.test(value);
}

export const isValidUrl = (value: string, errorMessage: string) => {
    const result = validateWebsiteUrl(value);
    return result ? true : errorMessage || 'Url Not Valid';
}

export const toCapitalize = (value: string) => {
    return value?.charAt(0).toUpperCase() + value?.slice(1).toLowerCase();
}

export const isEmailValidWithoutMessage = (value: string) => {
    const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return pattern.test(value)
}

export const validateEmailSeparatedBySymbol = (emailText: string, symbol: string) => {
    const emailList = emailText.split(symbol)
    let errorStatus = true
    emailList.forEach(email => {
        if (isEmailValidWithoutMessage(email) === false) {
            errorStatus = false
        }
    })
    return errorStatus
}

export const validatePhoneNumber = (value: any, setValue: any, key='phoneNumber') => {
    let match;
    let val;
    const containsNumbersOnly = (/^\d+$/.test(value));
    if (value.length === 10) {
        match = value.match(/^(\d{3})(\d{3})(\d{3,4})$/);
    }
    if (match) {
        setValue(key, '+1 (' + match[1] + ') ' + match[2] + '-' + match[3]);
    }
    if (!containsNumbersOnly && value.length < 17 ) {
        val = value.substr(3)
        val = val.replace(/\D/g, '')
        setValue(key,val)
        return 'Invalid number';
    }
    if (value.length > 17) {
        setValue(key, value.substr(0, 17))
    }
    if(value.length < 10){
        return 'Invalid number';
    }
    return isNotEmpty(value, 'Mobile Phone Number is required')
}
