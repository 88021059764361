import React from 'react';
import { useEffect, useState, useContext } from 'react';
import InfoCard from '../../commonComponents/appComponents/accountInfoCard/accountInfoCard';
import InfoEditCard from '../../commonComponents/appComponents/accountInfoCard/accountInfoEditCard';
import InfoCardtextItem from '../../commonComponents/appComponents/accountInfoCard/accountInfoCardTextItem';
import { useMutation } from 'react-query';
import Select from '../../commonComponents/uiComponents/LDSelect';
import { useForm, Controller } from 'react-hook-form';
import Button from '../../commonComponents/uiComponents/LDButton';
import { validateEmail, isNotEmpty, toCapitalize, validatePhoneNumber } from './../../commonComponents/common/utils';
import UserContext from '../../store/user-context';
import LDTextField from '../../commonComponents/uiComponents/LDTextField';
import SpaceDonationService, { Service } from 'src/spaceDonationService';
import './styles.css';
import {  Theme, Tooltip, withStyles } from '@material-ui/core';
import { InfoCircle } from "@livingdesign/icons";


const service: Service = SpaceDonationService;

export const CustomTooltip = withStyles((theme: Theme) => ({
    tooltip: {
        backgroundColor: "#fff",
        color: "rgba(0, 0, 0, 0.87)",
        boxShadow: theme.shadows[1],
        fontSize: theme.typography.pxToRem(12),
        border: "1px solid #dadde9",
        padding: 20,
    },
    arrow: {
        color: "#fff",
        "&::before": {
            border: "1px solid #dadde9",
            boxShadow: theme.shadows[1],
        },
    },
}))(Tooltip);


const getPhoneNumber = ((num: any) => {
    let match: any = false;
    if (num?.length === 10) {
        match = num?.match(/^(\d{3})(\d{3})(\d{3,4})$/);
    }
    if (match) {
        return `+1 (${match[1]})${match[2]}-${match[3]}`;
    }
    return num;
})

const validateDuplicateEmail = (users: any[], secondaryContacts: any[], email: string) =>
    !users.map(z => z.email).includes(email) && !secondaryContacts.map(z => z.email).includes(email);


const eventContactsDesc =   <CustomTooltip title={<React.Fragment> Please list the volunteers that will help facilitate the event at the Walmart store.</React.Fragment>} placement='bottom'
arrow={true}>
            <span>Event Contacts <InfoCircle style={{  fontSize: '1.2rem', margin: 'auto' }} /></span></CustomTooltip>


export const CustomContactInfo = (props: any) => {
    const [editButtonClicked, setEditButtonClicked] = useState(false);
    const [secondaryContacts] = useState(props?.secondaryContacts ? props?.secondaryContacts : []);
    const myMail = props?.secondaryContacts ? props?.secondaryContacts[0]?.email : '';
    // filter((x:any) => myCId !== x.cid).
    const [mail, setMail] = useState(myMail);
    const phonenumber = secondaryContacts?.filter((x: any) => x.email === mail)[0]?.phoneNumber;
    const fname = secondaryContacts?.filter((x: any) => x.email === mail)[0]?.firstName;
    const lname = secondaryContacts?.filter((x: any) => x.email === mail)[0]?.lastName;
    const email = secondaryContacts?.filter((x: any) => x.email === mail)[0]?.email;

    useEffect(() => {
        if(props.deleteMutation.isSuccess) {
            props.setShowSuccessConfirm(true);
        }
    }, [props.deleteMutation.isSuccess]);

    return <div className='col-lg-6 col-md-12 col-xs-12 col-sm-12 ngo-account-event-contact-card'>
        {editButtonClicked && <CustomUserForm {...props} setEditButtonClicked={setEditButtonClicked}
        secondaryContacts={secondaryContacts}
        />}
        {!editButtonClicked && secondaryContacts?.length>0 &&
            <InfoCard
                showEditButton={true}
                editButtonName='Add New'
                buttonHandler={setEditButtonClicked}
                header={eventContactsDesc}
                footer={<Button disabled={props?.deleteMutation.isLoading}
                    onClick={() => props?.deleteMutation?.mutate(secondaryContacts?.filter((x: any) => x.email === mail)[0])}
                    variant='tertiary'>{props?.deleteMutation.isLoading ? 'Deleting' : 'Delete this contact'}</Button>}>
                <Select
                    value={mail}
                    label={<div className='space-request-questions-card-answer-text'>Select a Contact</div>}
                    onChange={(event: any) => {
                        setMail(event.target.value);
                    }}>
                    {secondaryContacts?.map((fa: any) => {
                        return <option key={fa.email} value={fa.email} selected={myMail === fa.email}>
                            {`${toCapitalize(fa.firstName)} ${toCapitalize(fa.lastName)}`}
                        </option>
                    })}
                </Select>
                <InfoCardtextItem
                    header='Full Name'>{toCapitalize(fname)} {toCapitalize(lname)}
                </InfoCardtextItem>
                <InfoCardtextItem
                    header='Email'>{email}</InfoCardtextItem>
                <InfoCardtextItem
                    header='Phone'>{getPhoneNumber(phonenumber)}</InfoCardtextItem>
            </InfoCard> }
            {!editButtonClicked && secondaryContacts?.length<=0 && <div>
                <InfoCard
                    showEditButton={true}
                    editButtonName='Add New'
                    buttonHandler={setEditButtonClicked}
                    header={eventContactsDesc}
                    footer={<div />}>
                    <div>No contacts added</div>
                </InfoCard>

            </div>
        }
    </div>
}

export const CustomUserForm = (props: any) => {
    const userContext: any = useContext(UserContext);
    const mutation = useMutation((data: any) =>
        service.saveSecondaryContact({ orgId: data.orgId, data: data.data }));
    const { control, handleSubmit, errors, getValues, setValue } = useForm({
        mode: 'onChange',
        defaultValues: {
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            permissionToEmail: true
        }
    });

    useEffect(() => {
        if (mutation.isSuccess) {
            props.setShowSuccessConfirm(true);
        }

    }, [mutation.isSuccess]);

    useEffect(() => {
        if (mutation.isError) {
            props.setShowErrorPopUp(true);
            props.setError(mutation?.error);
        }
    }, [mutation.isError]);

    const onSubmit = () => {
        mutation.mutate({ orgId: userContext?.organization?.id, data: getValues() })
    };

    return <div>
        <form onSubmit={handleSubmit(onSubmit)}>
        <InfoEditCard
                header={eventContactsDesc}
                buttonSection={
                    <div className='info-card-buttons-spaced'>
                    <Button onClick={() => { props.setEditButtonClicked(false)}} disabled={mutation.isLoading}>Cancel</Button>
                    <Button type='submit' disabled={mutation.isLoading} variant='primary'>{mutation.isLoading ? 'Saving' : 'Save'}</Button>
                </div>
                }
                >
            <div>
                <Controller
                    control={control}
                    name='firstName'
                    defaultValue={''}
                    data-testid='firstName'
                    as={
                        <LDTextField
                            label='First name'
                            required={true}
                            type='text'
                        />
                    }
                    rules={{
                        required: 'First name is required',
                        validate: (value: any) => {
                            return isNotEmpty(value, 'First name is required')
                        }
                    }}
                />
                {errors?.firstName?.message && <span className='reg-form-error-container'
                    style={{ color: 'red' }}>{errors?.firstName?.message}</span>}
            </div>

            <div>
                <Controller
                    control={control}
                    name='lastName'
                    defaultValue={''}
                    data-testid='lastName'
                    as={
                        <LDTextField
                            label='Last name'
                            required={true}
                            type='text'
                        />
                    }
                    rules={{
                        required: 'Last name is required',
                        validate: (value: any) => {
                            return isNotEmpty(value, 'Last name is required')
                        }
                    }}
                />
                {errors?.lastName?.message && <span className='reg-form-error-container'
                    style={{ color: 'red' }}>{errors?.lastName?.message}</span>}
            </div>

            <div>
                <Controller
                    control={control}
                    name='email'
                    defaultValue={''}
                    data-testid='email'
                    as={
                        <LDTextField
                            label='Email'
                            type='text'
                            required={true}
                        />
                    }
                    rules={{
                        required: 'Email is required',
                        validate: (value: any) => {
                            return validateEmail(value) ?
                                validateDuplicateEmail(userContext?.organization?.orgContactList, props.secondaryContacts, value) ?
                                    true : 'Email already exist' :
                                'invalid email'
                        }
                    }}
                />
                {errors?.email?.message && <span className='reg-form-error-container'
                    style={{ color: 'red' }}>{errors?.email?.message}</span>}
            </div>
            <div>
                <Controller
                    control={control}
                    name='phoneNumber'
                    defaultValue={''}
                    data-testid='phoneNumber'
                    as={
                        <LDTextField
                            label='Mobile Phone Number'
                            required={true}
                            type='text'
                        />
                    }
                    rules={{
                        required: 'Mobile Phone Number is required',
                        validate: (value: any) => {
                            return validatePhoneNumber(value, setValue);
                        }
                    }}
                />
                {errors?.phoneNumber?.message && <span className='reg-form-error-container'
                    style={{ color: 'red' }}>{errors?.phoneNumber?.message}</span>}
            </div>
            </InfoEditCard>
        </form>
    </div>
}
