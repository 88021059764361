/* eslint-disable */

import { Article } from "@livingdesign/icons";
//import moment from 'moment';
import {
  Alert, Body, Card, Chip,
  ChipGroup, Grid, GridColumn, Heading, TextField
} from "@walmart-web/livingdesign-components";

import { FC } from "react";
import { useTranslation } from "react-i18next";
import { contextPath, DONATION_TYPE_VALUES } from "../../../constants";
import { getFaciltyValue, getLangObject } from "../../common/utils";
import SpaceDonationForm from "../gdmsSpaceDonation/SpaceInternationalDonationForm";
import ServiceDonationForm from "../serviceDonation/ServiceDonationForm";

import { GetLocation } from "./GetLocation";
//import "./style.css";

export interface OwnProps {
  history: any;
  donationCategory: any;
  data?: any;
  editable?: any;
  selectedLang: any;
  orgType: any;
  isLocationMandatory: any;
  createDonationMutate?: any;
  organizationForm: any;
}

const TypicalDonationTemplateForm: FC<OwnProps> = ({
  history,
  selectedLang,
  data,
  editable,
  orgType,
  isLocationMandatory,
  createDonationMutate,
  donationCategory,
  organizationForm
}) => {
  const { t } = useTranslation();
  const langObjectPath = getLangObject(donationCategory);
  const locationLabel = getFaciltyValue(donationCategory, t , data.donationDetails?.locationType);
  
  
  return (
    <>
      {editable ? "" : <GetLocation data={data} langObjectPath={langObjectPath} facilityLabel={locationLabel} />}
      <Card className="generic-donation-request-form">
        <div className="col-xs-12 col-md-12 col-sm-12 col-xs-12">
          <Heading as="h3" size="medium" weight={700} UNSAFE_style={{marginTop: "20px"}}>
            <Article size="medium" style={{ marginRight: "10px" }} />
            {editable ? t(`${langObjectPath}.form.reviewDetails`) : t(`${langObjectPath}.form.recipientDetails`)}
          </Heading>
          {editable && (
            <>
              <Alert
                actionButtonProps={{
                  children: t(`${langObjectPath}.form.editProfile`),
                  onClick() {
                    history.push({
                      pathname: `${contextPath}/dashboard/organization-profile?type=organization-profile`,
                      state: { currentPath: window.location.pathname + window.location.search }
                  });
                  },
                }}
                variant="info"
                style={{ marginTop: "24px" }}
              >
                {t(`${langObjectPath}.form.editRecipientBar`)}
              </Alert>
              <div style={{ marginTop: "5px" }}>
                <Body as="p" size="medium" weight={400}>
                  {t(`${langObjectPath}.form.requiredField`)}
                </Body>
              </div>
            </>
          )}
          <div style={{ marginTop: "25px", marginBottom: "5px" }}>
            <Heading as="h5" size="small" weight={700}>
              {t(`${langObjectPath}.form.specifyDonation`)}
            </Heading>
          </div>
          {/*@ts-ignore*/}
          <ChipGroup>
            {/*@ts-ignore*/}
            <Chip disabled={orgType !== "org_type_governmental"} placeholder={t(`${langObjectPath}.form.ge`)}>{t(`${langObjectPath}.form.ge`)}</Chip>
            {/*@ts-ignore*/}
            <Chip disabled={orgType !== "org_type_ngo"} placeholder={t(`${langObjectPath}.form.ngo`)}>{t(`${langObjectPath}.form.ngo`)}</Chip>
          </ChipGroup>

          <Grid style={{ marginTop: "25px" }}>
            {organizationForm.map((val: any, idx: any) => (
              <GridColumn
                sm={val.sm === 12 ? 12 : 6}
                key={idx}
                style={{ margin: "15px 0px" }}
              >
                <TextField
                  label={t(val.name)}
                  onChange={() => { }}
                  data-testid={val.name}
                  size="large"
                  readOnly
                  value={val.field}
                  disabled={editable ? false : true}
                />
              </GridColumn>
            ))}

            {donationCategory === DONATION_TYPE_VALUES.SPACE_INTERNATIONAL ?
              <SpaceDonationForm
                history={history}
                countryCode={window.countryCode}
                selectedLang={selectedLang}
                isLocationMandatory={isLocationMandatory}
                createDonationMutate={createDonationMutate}
                isReadOnly={editable ? false : true}
                editable={editable}
                data={data}
              /> : ""}

            {donationCategory === DONATION_TYPE_VALUES.SERVICE_DONATION ?
              <ServiceDonationForm
                history={history}
                countryCode={window.countryCode}
                selectedLang={selectedLang}
                isLocationMandatory={isLocationMandatory}
                createDonationMutate={createDonationMutate}
                isReadOnly={editable ? false : true}
                editable={editable}
                data={data}
              /> : ""}
          </Grid>
        </div>
      </Card>

    </>
  );
};

export default TypicalDonationTemplateForm;
