import React, { useEffect } from 'react';
import LDButton from '../../commonComponents/uiComponents/LDButton';
import LDDivider from '../../commonComponents/uiComponents/LDDivider';
import Card from '../../commonComponents/uiComponents/LDCard';
import CardItem from '../../commonComponents/uiComponents/LDCardItem';
import Badge from '../../commonComponents/uiComponents/LDBadge';
import Loader from '../../commonComponents/appComponents/loader/loader';
import { useQuery,useMutation } from 'react-query';
import SpaceDonationService, { Service } from 'src/spaceDonationService';
import { reactQueryConfigOptions } from '../../../../constants';
import './NationalCampaignEvent.css';
import CalenderPicker, { Legend } from './MultiDatePicker';
import { contextPath } from 'src/constants';
import moment from 'moment';
import PanelMoreDays from './PanelMoreDays';
import { Alert } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import CampaignApprovalModal from './campaign-footer/CampaignApprovalModal';
import DataTable from 'react-data-table-component';
import { useLocation, useNavigate } from 'react-router';
import withRouter, { useHistory } from 'src/polyfils/customRouter';
import Tooltip from '@mui/material/Tooltip';
const service: Service = SpaceDonationService;

export const badgeMsg = (type: string) => {
  let message;
  switch (type) {
    case 'SUBMITTED':
      message = `Pending`;
      break;
    case 'APPROVED':
      message = `Approved`;
      break;
    case 'REJECTED':
      message = `Opted-Out`;
      break;
    case 'CANCELLED':
      message = `Cancelled`;
      break;
  }
  return message;
};

export const dateFormats = (date: any, type: any) => {
  let message = '';
  if (date) {
    switch (type) {
      case 'month-year':
        message = moment(date).format('MMM DD, yyyy');
        break;
    }
  }
  return message;
};

export const getBadge = (type: any, value: string) => {
  let style = '';
  if (type === 'status') {
    type = ['SUBMITTED', 'APPROVED', 'REJECTED', 'CANCELLED'].includes(value.toUpperCase())
      ? type
      : null;
    style = `nc-badge-status-${value}`;
  }
  if (type) {
    return (
      <div className='nc-event-badge'>
        <Badge UNSAFE_className={` nc-badge ${style}`} variant='primary'>
          <span>{badgeMsg(type === 'status' ? value : type)}</span>
        </Badge>
      </div>
    );
  }
  return null;
};
export const DatesList = (list: any) => {
  return list.map((item: Date, index: number) => (
    <li key={index}>{moment(item).format('ddd, MMM DD')}</li>
  ));
};

export const sortArray = (dates: Date[]) => {
  if (!dates) {
    return [];
  }
  return dates.sort((a, b) => a.valueOf() - b.valueOf());
};
const timeoutFnToEvents=(history:any)=>{
  setTimeout(() => {
    history.push({
      pathname: `${contextPath}/space-donation/events/`,
    });
    return null;
  }, 2000);
}
const convertToDate = (dates: any) => {
  if (!dates) {
    return [];
  }
  return dates.map((date: any) => moment(date, 'MM-DD-YYYY').toDate());
};

const calculateMonth = (minDate:any,maxDate:any) => {
  const todayDate: Date = new Date();
  if (todayDate > new Date(minDate) && todayDate < new Date(maxDate)) {
    return todayDate;
  } else {
    return new Date(minDate);
  }
};
const getMinDate=(eventStartDateString:any)=>{
  return eventStartDateString
    ? moment(eventStartDateString, 'MM-DD-YYYY').toDate()
    : new Date()
}
const getMaxDate=(eventEndDateString:any)=>{
  return eventEndDateString
  ? moment(eventEndDateString, 'MM-DD-YYYY').toDate()
  : new Date()
}
export interface CalendarViewNgoProps {
  history?: any[];
  location?: {
    state: {
      ncId: any;
      orgId: any;
      data?: any;
      status: any;
    };
  };
}

const CalendarViewNgo: React.FunctionComponent<CalendarViewNgoProps> = () => {
  const location = useLocation();
  const state = location.state;

  const navigateTo = useNavigate();
  useEffect(() => {
    if (!state?.data?.id) {
      navigateTo({ pathname: `${contextPath}/space-donation/events` });
    }
  }, [state?.data?.id]);
  const [showApprove, setShowApprove] = React.useState(false);
  const [isSaveSuccess, setIsSaveSuccess] = React.useState(false);
  const [isSaveError, setisSaveError] = React.useState(false);
  const cancelReq = useMutation(service.cancelRequestStore);
  return (
    <>
      <div>
        <LDButton
          data-testid='event-button'
          variant='tertiary'
          size='small'
          className='buttonLink'
          onClick={() => {
            navigateTo({
              pathname: `${contextPath}/space-donation/events/`,
            });
          }}
        >
          Events /
        </LDButton>
        <LDButton
          data-testid='homeoffice-button'
          variant='tertiary'
          size='small'
          className='buttonLink'
          onClick={() => {
            navigateTo(`${contextPath}/space-donation/events/ngo-detail/`, {
              state: {
                ncId: state?.ncId, orgId: state?.orgId
              }
            })
          }}
        >
          Home Office Event /
        </LDButton>
        <span className='spanLink'>Store Request Details</span>
      </div>
      <div className='national-campaign-containter'>
        <div className='national-campaign-header-section'>
          <div className='national-campaign-header-section-left'>
            <div className='national-campaign-header-section-left'>
              <div className='national-campaign-section-row'>
                <span className='national-campaign-section-title'>
                  {'Store request details'}
                </span>
                {getBadge('status', state?.data?.status ?? 'SUBMITTED')}
              </div>
            </div>
          </div>
        </div>
        <NationalCampaignEventDashboard
          {...state}
          showApprove={showApprove}
          setShowApprove={setShowApprove}
          cancelReq={cancelReq}
          isSaveSuccess={isSaveSuccess}
          setIsSaveSuccess={setIsSaveSuccess}
          isSaveError={isSaveError}
          setisSaveError={setisSaveError}
        />
      </div>
    </>
  );
};
export interface NationalCampaignEventDashboardProps {
  ncId: any;
  orgId: any;
  data?: any;
  status: any;
  showApprove:any;
  setShowApprove: (values: any) => void;
  isSaveSuccess: boolean;
  setIsSaveSuccess:(values: any) => void;
  isSaveError: boolean;
  setisSaveError:(values: any) => void;
  cancelReq:any;
  approvedDate?: string;
}
export const NationalCampaignEventDashboard: React.FunctionComponent<
  NationalCampaignEventDashboardProps
> = (props) => {
  const id: any = props?.data?.id;
  const history=useHistory()
  const { isLoading, data: dataForCalendar } = useQuery(
    ['fetchNgoEventCalendar', { id }],
    service.fetchNgoEventCalendar,
    { ...reactQueryConfigOptions }
  );
  useEffect(() => {
    if (props?.cancelReq?.isSuccess) {
      hideAllAlerts();
      props?.setIsSaveSuccess(true);
      timeoutFnToEvents(history);
    }
  }, [props?.cancelReq?.isSuccess]);
  useEffect(() => {
    if (props?.cancelReq.isError === true) {
      hideAllAlerts();
      props?.setisSaveError(true);
    }
  }, [props?.cancelReq?.isError]);

  const handleApproveAction = (userAction: string) => {
    props?.setShowApprove(false);
    if (userAction === 'approve') {
      props?.cancelReq.mutate({ ncId:props?.ncId, orgId:props?.orgId,storeNbr:props?.data?.storeNbr });
    } else if (userAction === 'cancel') {
      props?.setShowApprove(false);
    }
  };
  const hideAllAlerts = () => {
    props?.setIsSaveSuccess(false);
    props?.setisSaveError(false);
  };
  return (
    <>
      {(isLoading || props?.cancelReq?.isLoading) && <Loader />}
      <div className='alert-section-calendar'>
        {props?.isSaveSuccess && (
          <AlertInfo
            level='success'
            message='Successfully Cancelled'
            hideAllAlerts={hideAllAlerts}
          />
        )}
        {props?.isSaveError && (
          <AlertInfo
            level='error'
            message='Something went wrong while cancelling the request'
            hideAllAlerts={hideAllAlerts}
          />
        )}
      </div>
      <div className='container-event'>
        <FetchNationalCampaignEvent {...props} approvedDate={dataForCalendar?.approvedDate} />
        <FetchNationalCampaignCalendarView {...props} {...dataForCalendar} />
      </div>
      {props.status &&
        (props.status === 'APPROVED' || props.status === 'SUBMITTED') && (
          <>
            <LDButton
              variant='tertiary'
              size='small'
              className='button-cancel-request-store'
              data-testid='button-cancel-request-store'
              onClick={() => {
                props?.setShowApprove(true);
              }}
            >
              Cancel request
            </LDButton>
            {props?.showApprove && (
              <CampaignApprovalModal
                userAction={handleApproveAction}
                bodyText={'Are you sure want to cancel the store?'}
              />
            )}
          </>
        )}
    </>
  );
};

export interface FetchNationalCampaignEventItemProps {
  name: any;
  value: any;
  verified?: boolean;
  display?: any;
}
export const NationalCampaignEventItem: React.FunctionComponent<
  FetchNationalCampaignEventItemProps
> = ({ name, value, display = 'flex' }) => {
  return (
    <CardItem
      className={`national-campaign-card-content-items national-campaign-card-content-items-${display} align-items-start`}
    >
      <div className='national-campaign-card-key'>{name}:</div>
      <div className='national-campaign-card-value pl-2'>{value}</div>
    </CardItem>
  );
};
export interface FetchNationalCampaignProps {
  dataForCalendar: any;
}
export const FetchNationalCampaignEvent: React.FunctionComponent<
  NationalCampaignEventDashboardProps
> = ({ data , approvedDate }) => {
  return (
    <>
      <div className='national-campaign-card-view'>
        <Card className='national-campaign-card-top'>
          <div className='national-campaign-card-header'>
            <CardItem className='national-campaign-card-header-title'>
              {'Store information'}
            </CardItem>
          </div>
          <LDDivider className='national-campaign-header-section-divider-card' />
          <div className='national-campaign-card-content'>
            <NationalCampaignEventItem
              name={'Approved Date'}
              value={dateFormats(approvedDate, 'month-year')}
            />
            <NationalCampaignEventItem
              name={'Store format'}
              value={data?.storeInfo?.storeFormat}
            />
            <NationalCampaignEventItem
              name={'Store number'}
              value={data?.storeNbr}
            />
            <NationalCampaignEventItem
              name={'Address'}
              value={data?.storeInfo?.address}
            />
            <NationalCampaignEventItem
              name={'City'}
              value={data?.storeInfo?.city}
            />
            <NationalCampaignEventItem
              name={'State'}
              value={data?.storeInfo?.state}
            />
            <NationalCampaignEventItem
              name={'Zip'}
              value={data?.storeInfo?.postalCode}
            />
          </div>
        </Card>
      </div>
    </>
  );
};

export interface FetchNationalCampaignCalendarProps {
  spaceNationalEventStatus: any;
  eventSlots?: any[];
  eventStartDateString: string;
  eventEndDateString: string;
  data?: any;
  status?: string;
  maxAllowedDays: number;
  ncId?: any;
  availableEditsLeft?:number;
  editEventFlag?:boolean;
}

export const FetchNationalCampaignCalendarView: React.FunctionComponent<
  FetchNationalCampaignCalendarProps
> = ({
  spaceNationalEventStatus = {},
  eventSlots,
  eventStartDateString,
  eventEndDateString,
  data,
  status,
  maxAllowedDays,
  ncId,
  availableEditsLeft,
  editEventFlag,
}) => {
  const mutation = useMutation(service.updateEventRequestDays);
  const [isSaveSuccess, setIsSaveSuccess] = React.useState(false);
  const [isSaveError, setisSaveError] = React.useState(false);
  const [tableData, setTableData] = React.useState([]);
  const history= useHistory();
  useEffect(() => {
    if (mutation.isSuccess === true) {
      setIsSaveSuccess(true);
      timeoutFnToEvents(history);
    }
  }, [mutation.isSuccess]);
  const eventSlotWithStatus = eventSlots?.map((obj: any) => {
    const obj2 = {...obj,selected:false};
    return obj2;
  })

  useEffect(() => {
    if (mutation.isError === true) {
      setisSaveError(true);
    }
  }, [mutation.isError]);
  const hideAllAlertsforCalendar = () => {
    setisSaveError(false);
    setIsSaveSuccess(false);
  };
  const minDate = getMinDate(eventStartDateString);
  const maxDate = getMaxDate(eventEndDateString);
  const defaultCalendarMonth: Date = calculateMonth(minDate,maxDate);

  const getProposedDatesMutation = useMutation((updatedData: any) => service.fetchProposedDates(updatedData));
  const { data: eventInfo } = useQuery(['getEventInfo', { id: ncId }],
        service.getEventInfo, { refetchOnWindowFocus: false })

  useEffect(() => {
    getProposedDatesMutation.mutate({
      ncId: data?.ncId,
      orgId: data?.spaceOrganizationId,
      storeNumber: data?.storeNbr
    })
  }, []);

  useEffect(() => {
    setTableData(getProposedDatesMutation.data);
  }, [getProposedDatesMutation.data]);


const columns = [
  {
      name: 'Date',
      selector: (row: any) => moment(row?.eventDateString).format('MMM DD'),
      sortable: true,
      center: false,
      minWidth: '22%',
  },
  {
      name: 'Time slots',
      selector: (row: any) => {
        return row.eventSlots.map((item:any)=> {
          const classname = 'nc-badge-slot-status-' + item?.status
          const status = item?.status !== undefined ? item?.status : 'PENDING'
          const title = item.label + ' is ' + status
         return <Tooltip title={title} placement='right'>
         <div className={classname}>{item.label}</div></Tooltip>
        });
      },
      minWidth: '45%',
      center: false,
  },
  {
      name: 'Status',
      selector: (row: any) => {
        let style = '';
        style = `nc-badge-status-${row?.storeStatus}`;
        return <Badge UNSAFE_className={` nc-badge ${style}`} variant='primary'>
          <span>{badgeMsg(row?.storeStatus)}</span>
        </Badge>
      },
      center: false,
      minWidth: '31%',
  },
]
  return (
    <>
      <div className='national-campaign-calendar-view'>
        <div className='alert-section'>
          {(isSaveSuccess||isSaveError) && (
            <AlertInfo
              level={isSaveSuccess ? 'success' : 'error'}
              message='Successfully Saved'
              hideAllAlerts={hideAllAlertsforCalendar}
            />
          )}
        </div>
        <Card className='national-campaign-calendar'>
          <div className='national-campaign-card-header'>
            <CardItem className='national-campaign-card-header-title'>
              Request information
            </CardItem>
            {status && (status === 'APPROVED' || status === 'SUBMITTED') && (
              <CardItem>
                {(status === 'APPROVED' || status === 'SUBMITTED') ? (
                  <PanelMoreDays
                    mutation={mutation}
                    selectedDates={[]}
                    disabledDates={[]}
                    approvedDates={convertToDate(spaceNationalEventStatus?.approvedDates)}
                    submittedDates={convertToDate(spaceNationalEventStatus?.submittedDates)}
                    rejectedDates={convertToDate(spaceNationalEventStatus?.rejectedDates)}
                    data={data}
                    hideAllAlerts={hideAllAlertsforCalendar}
                    timeSlots={eventSlotWithStatus}
                    disabled={false}
                    buttonTitle={'Edit'}
                    panelContent={'Select dates'}
                    minDate={minDate}
                    maxDate={maxDate}
                    defaultCalendarMonth={defaultCalendarMonth}
                    numDaysApproved={spaceNationalEventStatus?.approvedDates?.length || 0}
                    numDaysRejected={spaceNationalEventStatus?.rejectedDates?.length || 0}
                    numDaysSubmitted={spaceNationalEventStatus?.submittedDates?.length || 0}
                    maxAllowedDays = {maxAllowedDays}
                    consecutiveDaysRestriction={eventInfo?.consecutiveDays?.value}
                    restrictedDays={eventInfo?.consecutiveDays?.detail}
                    proposedDatesWithSlots={tableData}
                    status={status}
                    availableEditsLeft={availableEditsLeft}
                    editEventFlag={editEventFlag}
                  />
                ) : (
                 null
                )}
              </CardItem>
            )}
          </div>
          <div className='national-campaign-calendar-content'>
            <div className='calendar-view-first'>
              <CardItem className={`national-campaign-calendar-card-content`}>
                <div className='national-campaign-calendar-card-header-title'>
                  National Campaign Calendar
                </div>
              </CardItem>
              <CardItem className={`national-campaign-calendar-card-content`}>
                <CalenderPicker
                  approvedDates={convertToDate(
                    spaceNationalEventStatus?.approvedDates
                  )}
                  submittedDates={convertToDate(
                    spaceNationalEventStatus?.submittedDates
                  )}
                  rejectedDates={convertToDate(
                    spaceNationalEventStatus?.rejectedDates
                  )}
                  onChangeAvailable={() => {
                    return null;
                  }}
                  disabled={true}
                  minDate={minDate}
                  maxDate={maxDate}
                  defaultCalendarMonth={defaultCalendarMonth}
                />
                <div className='legend-manage-nc'>
                  <Legend label='Approved' type='approved' />
                  <Legend label='Pending' type='pending' />
                  <Legend label='Opted Out' type='optedOut' />
                </div>
              </CardItem>
            </div>
            <div className='calendar-view-second'>
              <DataTable
                className='ngo-nc-event-dataTable'
                columns={columns}
                fixedHeader={true}
                fixedHeaderScrollHeight='625px'
                data={tableData}/>
              </div>
            </div>
          </Card>
        </div>
      </>
    );
  };

// @ts-ignore
export default withRouter(CalendarViewNgo);
export const AlertInfo = (props: any) => (
  <div className='alert-box'>
    <Alert severity={props.level}>{props.message}</Alert>
    <>
      <CloseIcon onClick={props.hideAllAlerts} />
    </>
  </div>
);
