import React, { useEffect, useState } from 'react'
import { Typography, InputLabel, Select, MenuItem, FormControl, TextField, makeStyles, Theme } from '@material-ui/core';
import GlobalDonationDataService from './../../service';
import LoaderComponent from './../LoaderComponent';
import { Controller, useForm } from 'react-hook-form';
import { contextPath, countryCodeToMarketCode, taxIdFormat, taxIdPlaceholderTxt } from './../../constants';
import { useTranslation } from 'react-i18next';
import ErrorSnackbarComponent from 'dynamic-form-components/lib/components/menu/ErrorSnackbarComponent';
import analytics from '../../adobe-analytics/analytics';
import tracker from '../../adobe-analytics/tracker';
import { getFilteredOrgType, OrganizationTypes, stringFormat } from '../common/utils';
import { useNavigate } from 'react-router-dom';
import SuccessAlertDailog from 'src/components/dialogComponents/SuccessAlertDailog';
import { ANALYTICS } from '../../adobe-analytics/analytics-dataelemets';

const useStyles = makeStyles((_theme: Theme) => ({
    selectFormControl: {
        color: 'rgba(0, 0, 0, 0.54) !important',
        minWidth: '300px',
        fontFamily: ' var(--appFont)',
        backgroundColor: '#fff',
        marginRight: '1rem',
        marginBottom: '1rem',
        fontSize: '14px !important',
    },
    selectLabel: {
        color: 'rgba(0, 0, 0, 0.54)  !important',
        fontFamily: ' var(--appFont)',
        marginLeft: '10px',
        backgroundColor: '#fff',
        fontSize: '14px !important',
    },
    selectIcon: {
        color: 'rgba(0, 0, 0, 0.54)  !important'
    },
    menuItemRoot: {
        color: 'rgba(0, 0, 0, 0.54) ',
        fontFamily: ' var(--appFont)',
        minWidth: '250px',
    },
    menuItemselected: {
        color: 'rgba(0, 0, 0, 0.54) '
    },
    selectBoxBorder: {
        marginBottom: '12px',
        marginRight: '12px',
        display: 'inline-flex'
    },
}));
const OrgDetailsFormComponent = (props: any) => {

    const navigateTo = useNavigate()
    // @ts-ignore
    const { t, i18n } = useTranslation();
    const classes = ({ ...props.classes, ...useStyles() })
    const orgTypes: any = []
    const states: any = []
    // const cities: any = []
    const governmentCategory: any = []
    const orgTypeDropDown: React.ReactElement[] = [];
    const governementCategoryDropDown: React.ReactElement[] = []
    const stateDropDown: React.ReactElement[] = []
    // const cityDropDown: React.ReactElement[] = []
    const [orgTypeList, setOrgTypeList] = useState(orgTypes)
    const [stateList, setStateList] = useState(states)
    // const [cityList, setCityList] = useState(cities)
    const [governmentCategoryList, setGovernmentCategoryList] = useState(governmentCategory)
    const [organizationType, setOrgType] = useState(props.defaultFormValues?.organizationType)
    // @ts-ignore
    const [province, setProvince] = useState(props.defaultFormValues?.province)
    // @ts-ignore
    const [city, setCity] = useState(props.defaultFormValues?.province)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [showCancelDailog, setShowCancelDailog] = useState(false);
    const selectedLang = props.selectedLang;
    const sampleTaxId = taxIdPlaceholderTxt[props.countryCode] || ''

    const {
        handleSubmit,
        control,
        errors,
        setValue,
        watch
    } = useForm({
        mode: 'onChange',
        defaultValues: { ...props.defaultFormValues }
    });

    useEffect(() => {
        analytics.trackEventInfo({
            type: tracker.EVENTS.CLICK,
            actionOn: tracker.ACTION_ON.REGISTER__BUTTON.name,
            location:ANALYTICS.login.loginPage
        });
        analytics.trackPageInfoGdmsExternal(ANALYTICS.registerPage.registerOrgDetailsPage1);
    }, [])


    const orgType = watch(
        'organizationType');
        useEffect(() => {
            if(!props?.returned){
            setShowCancelDailog(true)
            }
        }, [orgType]);
    useEffect(() => {
        if (!props.returned && (!orgTypeList || orgTypeList?.length === 0)) {
            setLoading(true)
            GlobalDonationDataService.getOrganizationTypes({}).then((res) => {
                setLoading(false)
                setOrgTypeList(getFilteredOrgType(res))
            })
        } else {
            setOrgTypeList(props.defaultFormValues?.orgTypeList)
            setValue('organizationType', props.defaultFormValues?.organizationType)
            setOrgType(props.defaultFormValues?.organizationType)
        }
        if (!props.returned && (!stateList || stateList.length === 0)) {
            setLoading(true)
            GlobalDonationDataService.getStateList({ countryCode: props.countryCode }).then((res) => {
                setLoading(false)
                setStateList(res)
            })
        } else {
            setStateList(props.defaultFormValues?.stateList)
            setValue('province', props.defaultFormValues?.province)
            setProvince(props.defaultFormValues?.province)
            // setCityList(props.defaultFormValues?.province?.cityList)
            setValue('city', props.defaultFormValues?.city)
            setCity(props.defaultFormValues?.city)
        }
    }, [props.returned])

    useEffect(() => {
        if ((!props.returned && organizationType?.keyText === 'org_type_governmental' && !governmentCategoryList) ||
            (!governmentCategoryList && props.returned)) {
            setLoading(true)
            GlobalDonationDataService.getSelectAreaData({}).then((res) => {
                setLoading(false)
                setGovernmentCategoryList(res)
            })
        } else {
            setGovernmentCategoryList(props.defaultFormValues?.governmentCategoryList)
            setValue('governmentCategory', props.defaultFormValues?.governmentCategory)
        }
    }, [organizationType, props.returned])

    const validateTaxId = (taxId: any) => {
        const format = taxIdFormat[props.countryCode]
        if (taxId && format) {
            return format.test(taxId) ? true : t('orgRegForm.validationMsg.taxIdError')
        }
        return true
    }

    const onConfirm = () => {
        setShowCancelDailog(false)
    }

    const onSubmit = (data: any) => {
        analytics.trackEventInfo({
            type: tracker.EVENTS.CLICK,
            actionOn: tracker.ACTION_ON.NEXT_STEP_BUTTON.name,
            location: ANALYTICS.login.loginPage
        });
        let countryCode: string = localStorage.getItem('countryCode') || "CA";
       
        const payload = {
            isRegister: false,
            // @ts-ignore
            orgLegalName: data.orgLegalName,
            organizationType: data.organizationType,
            governmentCategory: data?.governmentCategory ? data?.governmentCategory : null,
            // @ts-ignore
            taxId: data.taxId,
            country: localStorage.getItem('countryName'),
            countryCode: countryCode,
            market: countryCodeToMarketCode[countryCode],
            externalSource: true,
            address: {
                // @ts-ignore
                province: data.province?.label,
                // @ts-ignore
                city: data.city,
                // @ts-ignore
                addressLine1: data.addressLine1,
                // @ts-ignore
                postalCode: data.postalCode,
                country: localStorage.getItem('countryName')
            },
            telephone: data.telephone,
            primaryOrgUser: {
                prefLanguage: localStorage.getItem('lang')
            }
        }
        setLoading(true)
        setError('')
        GlobalDonationDataService.registerNewUser(payload).then(
            (_res) => {
                setLoading(false)
                props.submit({
                    ...data,
                    orgTypeList,
                    governmentCategoryList,
                    stateList
                })
            },
        ).catch((err) => {
            setLoading(false)
            setError(err?.response?.data?.errorMessage || t('common.errorMesg.genericError'))
        });
    }

    const taxIdplaceholdertxt = t('orgRegForm.placeHolders.taxId')
    const taxIdPlaceholder = sampleTaxId ? stringFormat(taxIdplaceholdertxt, sampleTaxId) : ''

    return (
        <div className={classes.formElement}>
            <Typography className='section-header'>
                {t('orgRegForm.labels.orgDetails')}
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)} noValidate={true} autoComplete='off'>
                <div>
                    <FormControl
                        required={true}
                        style={{ width: '100%' }}
                        classes={{ root: classes.selectFormControl }}
                        size='medium' variant='outlined'
                        error={errors.organizationType?.message}>
                        <InputLabel classes={{ root: classes.selectLabel }} id='orgtype-selecct-input'>
                            {t('orgRegForm.labels.orgType')}
                        </InputLabel>
                        <Controller
                            control={control}
                            name='organizationType'
                            defaultValue={''}
                            as={
                                <Select
                                    classes={{ root: classes.selectRoot, icon: classes.selectIcon }}
                                    labelId='orgtype-select-label'
                                    id='organizationType'
                                    name='organizationType'
                                    autoWidth={false}
                                    defaultValue={''}
                                    displayEmpty={true}>
                                    {orgTypeList && orgTypeList?.map((item: any) => {
                                        orgTypeDropDown.push(<MenuItem
                                            classes={{
                                                root: classes.menuItemRoot,
                                                selected: classes.menuItemselected
                                            }}
                                            key={item.keyText} value={item}>{item.language[props.selectedLang]}</MenuItem>)
                                    })
                                    }
                                    {orgTypeDropDown}
                                </Select>
                            }
                            rules={{
                                required: t('orgRegForm.validationMsg.requiredField') as string,
                                validate: (value: any) => {
                                    setOrgType(value || props.defaultFormValues?.organizationType)
                                    setValue('organizationType', value || props.defaultFormValues?.organizationType)
                                    return true;
                                }
                            }}
                        />
                        {errors.organizationType?.message && <span className='reg-form-error-container'
                            style={{ color: 'red' }}>{errors.organizationType?.message}</span>}
                    </FormControl>
                </div>
                {organizationType?.keyText === 'org_type_governmental' ?
                    <div>
                        <FormControl
                            required={true}
                            style={{ width: '100%' }}
                            classes={{ root: classes.selectFormControl }}
                            size='medium' variant='outlined'
                            error={errors.governmentCategory?.message}>
                            <InputLabel classes={{ root: classes.selectLabel }}
                                id='governmentCategory-selecct-input'>
                                {t('orgRegForm.labels.govtCategory')}
                            </InputLabel>
                            <Controller
                                control={control}
                                name='governmentCategory'
                                as={
                                    <Select
                                        classes={{ root: classes.selectRoot, icon: classes.selectIcon }}
                                        labelId='governmentCategory-select-label'
                                        id='orggovernmentCategoryType'
                                        name='governmentCategory'
                                        autoWidth={false}
                                        defaultValue={props.defaultFormValues?.governmentCategory}
                                        onChange={(event: any) => {
                                            setGovernmentCategoryList(event?.target?.value || props.defaultFormValues?.governmentCategory)

                                        }}
                                        displayEmpty={true}>
                                        {governmentCategoryList?.map((item: any) => {
                                            governementCategoryDropDown.push(<MenuItem
                                                classes={{
                                                    root: classes.menuItemRoot,
                                                    selected: classes.menuItemselected
                                                }}
                                                key={item.keyText} value={item}>{item.language[props.selectedLang]}</MenuItem>)
                                        })}
                                        {governementCategoryDropDown}
                                    </Select>
                                }
                                rules={{
                                    required: t('orgRegForm.validationMsg.requiredField') as string,
                                }}
                            />
                            {errors.organizationType?.message && <span className='reg-form-error-container'
                                style={{ color: 'red' }}>{errors.organizationType?.message}</span>}
                        </FormControl>
                    </div> : <></>}
                <div>
                    <Controller
                        control={control}
                        name='orgLegalName'
                        defaultValue={''}
                        as={
                            <TextField
                                required={true}
                                id='orgLegalName'
                                label={t('orgRegForm.labels.orglegalName')}
                                placeholder={t('orgRegForm.placeHolders.orglegalName')}
                                helperText=''
                                defaultValue={''}
                                fullWidth={true}
                                margin='normal'
                                classes={{ root: classes.selectFormControl }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant='outlined'
                                error={errors.orgLegalName?.message}
                            />
                        }
                        rules={{
                            required: t('orgRegForm.validationMsg.requiredField') as string,
                        }}
                    />
                    {errors.orgLegalName?.message && <span className='reg-form-error-container'
                        style={{ color: 'red' }}>{errors.orgLegalName?.message}</span>}
                </div>
                <div>
                    <Controller
                        control={control}
                        name='taxId'
                        defaultValue={''}
                        as={
                            <TextField
                                required={(
                                    props.countryCode === 'CA' || organizationType?.keyText === OrganizationTypes.GE) ? false : true
                                }
                                id='taxId'
                                label={t('orgRegForm.labels.taxId')}
                                placeholder={taxIdPlaceholder as string}
                                helperText={''}
                                defaultValue={''}
                                fullWidth={true}
                                margin='normal'
                                classes={{ root: classes.selectFormControl }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant='outlined'
                                error={errors.taxId?.message}
                            />
                        }
                        rules={{
                            required: (
                                props.countryCode === 'CA' || organizationType?.keyText === OrganizationTypes.GE) ? false :
                                t('orgRegForm.validationMsg.taxIdError') as string,
                            validate: validateTaxId
                        }}
                    />
                    {errors.taxId?.message && <span className='reg-form-error-container'
                        style={{ color: 'red' }}>{errors.taxId?.message}</span>}
                </div>
                <div style={{ marginBottom: '15px' }}>
                    <Controller
                        control={control}
                        name='addressLine1'
                        defaultValue={''}
                        as={
                            <TextField
                                required={true}
                                id='addressLine1'
                                label={t('orgRegForm.labels.streetAddr')}
                                placeholder={t('orgRegForm.placeHolders.streetAddr')}
                                helperText=''
                                defaultValue={''}
                                fullWidth={true}
                                margin='normal'
                                classes={{ root: classes.selectFormControl }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant='outlined'
                                error={errors.addressLine1?.message}
                            />
                        }
                        rules={{
                            required: t('orgRegForm.validationMsg.requiredField') as string,
                        }}
                    />
                    {errors.addressLine1?.message && <span className='reg-form-error-container'
                        style={{ color: 'red' }}>{errors.addressLine1?.message}<br /></span>}
                </div>
                <div>
                    <FormControl
                        required={true}
                        style={{ width: '100%' }}
                        classes={{ root: classes.selectFormControl }}
                        size='medium' variant='outlined'
                        error={errors.province?.message}>
                        <InputLabel classes={{ root: classes.selectLabel }} id='province'>
                            {t('orgRegForm.labels.state')}
                        </InputLabel>
                        <Controller
                            control={control}
                            name='province'
                            defaultValue={''}
                            as={
                                <Select
                                    classes={{ root: classes.selectRoot, icon: classes.selectIcon }}
                                    labelId='province-select-label'
                                    id='province'
                                    name='province'
                                    autoWidth={false}
                                    defaultValue={''}
                                    displayEmpty={true}>
                                    {stateList?.map((state: any) => {
                                        stateDropDown.push(<MenuItem
                                            classes={{
                                                root: classes.menuItemRoot,
                                                selected: classes.menuItemselected
                                            }}
                                            key={state.label} value={state}>{state.label}</MenuItem>)
                                    })}
                                    {stateDropDown}
                                </Select>
                            }
                            rules={{
                                required: t('orgRegForm.validationMsg.requiredField') as string,
                                validate: (value: any) => {
                                    setProvince(value || props.defaultFormValues?.province)
                                    setValue('province', value || props.defaultFormValues?.province)
                                    return true;
                                }
                            }}

                        />
                        {errors.province?.message && <span className='reg-form-error-container'
                            style={{ color: 'red' }}>{errors.province?.message}</span>}
                    </FormControl>
                </div>
                <div>
                    <Controller
                        control={control}
                        name='city'
                        defaultValue={''}
                        as={
                            <TextField
                                required={true}
                                id='city'
                                label={t('orgRegForm.labels.city')}
                                placeholder={t('orgRegForm.placeHolders.city')}
                                helperText=''
                                fullWidth={true}
                                margin='normal'
                                classes={{ root: classes.selectFormControl }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant='outlined'
                                error={errors.city?.message}
                            />
                        }
                        rules={{
                            required: t('orgRegForm.validationMsg.requiredField') as string,
                        }}
                    />
                    {errors.city?.message && <span className='reg-form-error-container'
                        style={{ color: 'red' }}>{errors.city?.message}</span>}
                </div>
                <div>
                    <Controller
                        control={control}
                        name='postalCode'
                        defaultValue={''}
                        as={
                            <TextField
                                required={true}
                                id='postalCode'
                                label={t('orgRegForm.labels.postalCode')}
                                placeholder={t('orgRegForm.placeHolders.postalCode')}
                                helperText=''
                                fullWidth={true}
                                margin='normal'
                                classes={{ root: classes.selectFormControl }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant='outlined'
                                error={errors.postalCode?.message}
                            />
                        }
                        rules={{
                            required: t('orgRegForm.validationMsg.requiredField') as string,
                        }}
                    />
                    {errors.postalCode?.message && <span className='reg-form-error-container'
                        style={{ color: 'red' }}>{errors.postalCode?.message}</span>}
                </div>
                <div>
                    <Controller
                        control={control}
                        name='telephone'
                        defaultValue={''}
                        as={
                            <TextField
                                required={true}
                                id='telephone'
                                label={t('orgRegForm.labels.telephone')}
                                placeholder={t('orgRegForm.placeHolders.telephone')}
                                helperText=''
                                fullWidth={true}
                                margin='normal'
                                classes={{ root: classes.selectFormControl }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant='outlined'
                                error={errors.telephone?.message}
                            />
                        }
                        rules={{
                            required: t('orgRegForm.validationMsg.requiredField') as string,
                        }}
                    />
                    {errors.telephone?.message && <span className='reg-form-error-container'
                        style={{ color: 'red' }}>{errors.telephone?.message}</span>}
                </div>
                <div>
                    <div className='social-impact-btn-group'>
                        <div className='social-impact-btn-group'>
                            <span style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                {t('orgRegForm.labels.registeredTxt')}
                            </span>
                            &nbsp;&nbsp;&nbsp;
                            <button className='btn-link'
                                type='button'
                                style={{
                                    color: 'black',
                                    textDecoration: 'underline',
                                    fontWeight: 'normal',
                                    textTransform: 'none',
                                    fontSize: '14px'
                                }}
                                onClick={() => {
                                    analytics.trackEventInfo({
                                        type: tracker.EVENTS.CLICK,
                                        actionOn: tracker.ACTION_ON.LOGIN_BUTTON.name,
                                        location:ANALYTICS.registerPage.registerOrgDetailsPage1
                                    });
                                    navigateTo(`${contextPath}/Public/login?lang=${localStorage.getItem('country')}`);
                                }}>
                                {t('orgRegForm.labels.loginTxt')}
                            </button>
                            <button className={`sip-submit-btn go-btn-next`}
                                style={{
                                    float: 'right', marginTop: '-8px',
                                    minWidth: (props.selectedLang === 'en' || props.selectedLang === 'zh' ||
                                        props.selectedLang === 'es') ? '150px' : '220px'
                                }}
                                type='submit'>
                                {t('orgRegForm.labels.gotoNextBtn')}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
            <ErrorSnackbarComponent isError={error ? true : false} classes={classes}
                message={error}
            />
            {loading && <LoaderComponent fullScreen={true} />}
            {selectedLang && organizationType ?
                <SuccessAlertDailog
                    showDialog={showCancelDailog}
                    ok={onConfirm}
                    title={t('common.popUpMsg.orgTypehelpText.title')}
                    // title={organizationType.language[selectedLang]}
                    message={
                        <>
                            <strong>{t('common.popUpMsg.orgTypehelpText.message1')}</strong>
                            {t('common.popUpMsg.orgTypehelpText.message2')} <br/>
                            {t('common.popUpMsg.orgTypehelpText.message3')} <br/>
                            {t('common.popUpMsg.orgTypehelpText.message4')}<br/>
                            {t('common.popUpMsg.orgTypehelpText.message5')} <br/>
                            {t('common.popUpMsg.orgTypehelpText.message6')}<br/> 
                            {t('common.popUpMsg.orgTypehelpText.message7')}<br/> 
                            <strong> {t('common.popUpMsg.orgTypehelpText.message8')}</strong><br/> 
                            {t('common.popUpMsg.orgTypehelpText.message9')}<br/>  
                            {t('common.popUpMsg.orgTypehelpText.message10')} <br/> 
                            {t('common.popUpMsg.orgTypehelpText.message11')}<br/> 

                        </>
                    }
                /> : <></>}
        </div>
    )
}
export default OrgDetailsFormComponent;
